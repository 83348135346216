import React from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";

const QR = () => {
  const { t } = useTranslation();
  return (
    <>
      <div className="parent-container-short">
        <p className="qr-collar-title none">{t("qr-page-header-text")}</p>
        <div className="qr-collar-instruction-container">
          <img
            src={`../img/${localStorage.getItem("i18nextLng")}1.png`}
            alt="QR-COLLAR"
          />
        </div>
        <div className="qr-collar-order-container">
          <div className="qr-card card-1">
            <img src="../img/Untitled-2-2.png" alt="" />
            <p>{t("qrcollar")}</p>
            <ul>
              <li>
                <span className="check-list"></span>
                {t("you have control panel")}
              </li>
              <li>
                <span className="check-list"></span>
                {t("pet's profile update")}
              </li>
              <li>
                <span className="check-list"></span>
                {t("activation")} / {t("deactivation")}
              </li>
              <li>
                <span className="check-list"></span>
                {t("no monthly fee")}
              </li>
              <li>
                <span className="check-list"></span>
                {t("waterproof")}
              </li>
              <li>
                <span className="check-list"></span>
                {t("scratch, bite, and fade resistant")}
              </li>
              <li>
                <span className="check-list"></span>
                {t("compatible with all smartphones")}
              </li>
              <li>
                <span className="check-list"></span>
                {t("no batteries needed")}
              </li>
            </ul>
            <Link
              className="qr-collar-button"
              type="button"
              to="/order/qrcollar"
            >
              <span>{t("price")}:</span> 55 {t("gel")}
            </Link>
          </div>

          <div className="qr-card card-2">
            <img src="../img/route.png" alt="" />
            <p>{t("gps collar")}</p>
            <ul>
              <li>
                <span className="check-list"></span>
                {t("you have control panel")}
              </li>
              <li>
                <span className="check-list"></span>
                {t("location in live mode")}
              </li>
              <li>
                <span className="check-list"></span>
                {t("no monthly fee")}
              </li>
              <li>
                <span className="check-list"></span>
                {t("waterproof")}
              </li>
              <li>
                <span className="check-list"></span>
                {t("compatible with all smartphones")}
              </li>
              <li>
                <span className="check-list"></span>
                {t("no batteries needed")}
              </li>
            </ul>
            <Link
              className="qr-collar-button"
              type="button"
              to="/order/qrcollar"
            >
              <span>{t("price")}:</span> 205 {t("gel")}
            </Link>
          </div>

          <div className="qr-card card-3">
            <img src="../img/gift-box.png" alt="" />
            <p>{t("gift a friend")}</p>
            <ul></ul>
            <Link
              className="qr-collar-button"
              type="button"
              to="/order/qrcollar"
            >
              <span>{t("price")}:</span> 230 {t("gel")}
            </Link>
          </div>
        </div>
        <div className="tags-card-container-for-homepage">
          <h3> {t("pethub tag features")} </h3>{" "}
          <p className="title-description">
            {" "}
            {t("pethub tag features description")}{" "}
          </p>{" "}
          <div className="tags-card-inside-container-for-homepage">
            <span className="tag-card-for-home-page">
              <img src="img/petprofile.png" alt="" />
              <p className="tag-card-title"> {t("update information")} </p>{" "}
              <p className="tag-card-description-1">
                {" "}
                {t("update-info-description-1")}{" "}
              </p>{" "}
              <p className="tag-card-description-2">
                {" "}
                {t("update-info-description-2")}{" "}
              </p>{" "}
            </span>{" "}
            <span className="tag-card-for-home-page">
              <img src="img/gpsalerts.webp" alt="" />
              <p className="tag-card-title"> {t("gps notification")} </p>{" "}
              <p className="tag-card-description-1">
                {" "}
                {t("gps-collar-description-1")}{" "}
              </p>{" "}
              <p className="tag-card-description-2">
                {" "}
                {t("gps-collar-description-2")}{" "}
              </p>{" "}
            </span>{" "}
            <span className="tag-card-for-home-page">
              <img src="img/privacycontrol.png" alt="" />
              <p className="tag-card-title"> {t("privacy control")} </p>{" "}
              <p className="tag-card-description-1">
                {" "}
                {t("privacy-control-description-1")}{" "}
              </p>{" "}
            </span>{" "}
          </div>{" "}
        </div>{" "}
        <p className="qr-collar-title">{t("video instruction")}</p>
        <iframe
          className="video-instruction"
          src="https://www.youtube.com/embed/MqPKWN0IhsA?si=DlkzcN_utmF_pQrv"
          frameborder="0"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
          allowfullscreen
        ></iframe>
        {/* <video autoplay>
          <source src="./img/petq.mp4" type={"video/mp4"} />
        </video> */}
      </div>
    </>
  );
};

export default QR;
