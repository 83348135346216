import React, { useEffect } from "react";
import { connect } from "react-redux";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { useTranslation } from "react-i18next";

import { changePasswordAction } from "../actions/authActions";
import { clearServerErrors } from "../actions/errors";

const ChangePassword = ({
  changePasswordAction,
  serverErrors,
  clearServerErrors,
}) => {
  const { t } = useTranslation();
  const ChangePasswordSchema = yup.object().shape({
    current_password: yup.string().required(),
    password: yup.string().required(),
    confirm_password: yup
      .string()
      .required()
      .oneOf([yup.ref("password"), null], "Passwords must match"),
  });

  const {
    register,
    formState: { errors },
    handleSubmit,
    reset,
  } = useForm({
    mode: "onChange",
    resolver: yupResolver(ChangePasswordSchema),
  });

  useEffect(() => {
    clearServerErrors();
  }, []);
  const handleSubmitClick = (data) => {
    changePasswordAction(data);
    reset();
  };
  return (
    <>
      <p className="personal-info-header-container">{t("change password")}</p>
      <form
        onSubmit={handleSubmit(handleSubmitClick)}
        noValidate
        autoComplete="off"
        className="change-password"
      >
        <div className="user-update-fields">
          <input
            type="password"
            placeholder={t("current_password")}
            {...register("current_password")}
          />
          {errors.current_password && (
            <a className="error-text">
              {t(errors.current_password?.message.key)}
            </a>
          )}
          {serverErrors.current_password && (
            <p className="help is-danger">{t(serverErrors.current_password)}</p>
          )}
        </div>
        <div className="user-update-fields">
          <input
            type="password"
            placeholder={t("new_password")}
            {...register("password")}
          />
          {errors.password && (
            <p className="error-text">{t(errors.password?.message.key)}</p>
          )}
          {serverErrors.password && (
            <p className="help is-danger">{t(serverErrors.password)}</p>
          )}
        </div>
        <div className="user-update-fields">
          <input
            type="password"
            id="password"
            placeholder={t("confirm_password")}
            {...register("confirm_password")}
          />
          {errors.confirm_password && (
            <p className="error-text">{t(errors.confirm_password?.message)}</p>
          )}
          {serverErrors.confirm_password && (
            <p className="help is-danger">{t(serverErrors.confirm_password)}</p>
          )}
        </div>
        <input className="submit" type="submit" value={t("change_password")} />
      </form>
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    serverErrors: state.errors.errors,
  };
};

export default connect(mapStateToProps, {
  changePasswordAction,
  clearServerErrors,
})(ChangePassword);
