import React, { useEffect, useRef } from "react";
import { connect } from "react-redux";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";

import { getPostsAction } from "../actions/postActions";
import { getOverallStatisticsAction } from "../actions/orderActions";
import PostCard from "../components/PostCard";
import MissingPostCard from "../components/MissingPostCard";
import FoundPostCard from "../components/FoundPostCard";
import Filter from "../components/Filter";
import Banner from "../components/Banner";
import CustomQr from "../components/CustomQr";
import RightContianer from "../components/RightContainer";
import { Swiper, SwiperSlide } from "swiper/react";
import {
  Grid,
  Navigation,
  Pagination,
  Autoplay,
  Scrollbar,
  A11y,
  Mousewheel,
} from "swiper/modules";

const Home = ({
  getPostsAction,
  posts,
  stickyBanner,
  overallStatistics,
  getOverallStatisticsAction,
}) => {
  const VIPSwiperRef = useRef(null);
  const missingSwiperRef = useRef(null);
  const foundSwiperRef = useRef(null);
  const qrTagSwiperRef = useRef(null);
  useEffect(() => {
    getPostsAction();
    getOverallStatisticsAction();
  }, []);

  const renderMainFilter = (post_type) => {
    if (posts[post_type] !== null) {
      return <Filter />;
    }
    return null;
  };
  console.log(overallStatistics);
  const renderPosts = (post_type) => {
    if (posts[post_type] !== null) {
      return posts[post_type].map((item, i) => {
        if (post_type === "missing_posts") {
          return (
            <SwiperSlide>
              <MissingPostCard data={item} key={i} />{" "}
            </SwiperSlide>
          );
        } else if (post_type === "found_posts") {
          return (
            <SwiperSlide>
              <FoundPostCard data={item} key={i} />{" "}
            </SwiperSlide>
          );
        } else if ("vip_posts") {
          return (
            <SwiperSlide>
              <PostCard data={item} key={i} />{" "}
            </SwiperSlide>
          );
        } else {
          return <PostCard data={item} key={i} />;
        }
      });
    }
  };

  const handlePrev = (swiperRef) => {
    if (swiperRef.current) {
      swiperRef.current.swiper.slidePrev();
    }
  };

  const handleNext = (swiperRef) => {
    if (swiperRef.current) {
      swiperRef.current.swiper.slideNext();
    }
  };

  const { t } = useTranslation();
  return (
    <>
      <div className="">
        <div className="qr-tags-container qr-tags-container-2">
          <Swiper
            className="qr-tags-swiper"
            modules={[Autoplay, Navigation, Pagination, A11y]} // Mousewheel
            spaceBetween={0}
            slidesPerView={1}
            navigation
            speed={350}
            effect="fade"
            autoplay={{
              delay: 7000,
            }}
            loop={true}
            mousewheel={true}
            pagination={{ clickable: true }}
          >
            {/* <SwiperSlide>
                                                                                                                                                                                                                                                                                                                                                  <video
                                                                                                                                                                                                                                                                                                                                                    // width="100%"
                                                                                                                                                                                                                                                                                                                                                    height="100%"
                                                                                                                                                                                                                                                                                                                                                    src="https://smartpet.ge/images/ut2_banner_videos/72_mera-final.mp4"
                                                                                                                                                                                                                                                                                                                                                    type="video/mp4"
                                                                                                                                                                                                                                                                                                                                                    loop
                                                                                                                                                                                                                                                                                                                                                    autoPlay
                                                                                                                                                                                                                                                                                                                                                    muted
                                                                                                                                                                                                                                                                                                                                                  ></video>
                                                                                                                                                                                                                                                                                                                                                </SwiperSlide> */}{" "}
            <SwiperSlide>
              <div className="qr-tags-container-overlay">
                <div className="text-for-qr-tags-container">
                  <div className="qr-tag-main-text">
                    <p className="main-text">
                      <span className="priority"> {t("best gift")} </span>{" "}
                      {t("for your pets")}{" "}
                    </p>{" "}
                    <p className="dont_lose_frinds">
                      {" "}
                      {t("don't lose your friends")}{" "}
                    </p>{" "}
                    <p className="pethub_app_text"> {t("pethub app text")} </p>{" "}
                    <ul>
                      {" "}
                      <li>
                        <span className="our-services-check"> </span>{" "}
                        {t("pet's profile update")}{" "}
                      </li>{" "}
                      <li>
                        <span className="our-services-check"> </span>{" "}
                        {t("activation")}/ {t("deactivation")}{" "}
                      </li>{" "}
                      <li>
                        <span className="our-services-check"> </span>{" "}
                        {t("no batteries needed")}{" "}
                      </li>{" "}
                      <li>
                        <span className="our-services-check"> </span>{" "}
                        {t("it works 24/7")}{" "}
                      </li>{" "}
                    </ul>{" "}
                    <div className="home-banner-order-button">
                      <Link to="/order/qrcollar"> {t("buy now")} </Link>{" "}
                      {/* <Link to="/ordergpscollar">{t("gps tracker")}</Link> */}{" "}
                      {/* <Link to="/qrcollar"> {t("how it works")} </Link>{" "} */}{" "}
                    </div>{" "}
                  </div>

                  <img className="right-face-img" src="img/Pethub_tag-06.png" />
                </div>{" "}
              </div>{" "}
            </SwiperSlide>{" "}
          </Swiper>{" "}
        </div>{" "}
        <div className="parent-container-short">
          <div className="members-and-tags-counter-container">
            {" "}
            {/* <div className="">
                                                                                                                                                                                                                                                                                                                                                  <span>{t("users")}</span>
                                                                                                                                                                                                                                                                                                                                                  <span>21187</span>
                                                                                                                                                                                                                                                                                                                                                </div> */}{" "}
            <div className="">
              <span> {t("members")} </span>{" "}
              <span> {overallStatistics.total_members} </span>{" "}
            </div>{" "}
            <div className="">
              <span> {t("active qr tags")} </span>{" "}
              <span> {overallStatistics.active_tag} </span>{" "}
            </div>{" "}
            {/* <div className="">
                                                                                                                                                                                                                                                                                                                                                  <span>{t("active gps tracker")}</span>
                                                                                                                                                                                                                                                                                                                                                  <span>471</span>
                                                                                                                                                                                                                                                                                                                                                </div> */}{" "}
            <div className="">
              <span> {t("lost pets")} </span>{" "}
              <span> {overallStatistics.lost} </span>{" "}
            </div>{" "}
            <div className="">
              <span> {t("found pets")} </span>{" "}
              <span> {overallStatistics.found} </span>{" "}
            </div>{" "}
          </div>{" "}
        </div>{" "}
        <div className="parent-container-short">
          <div className="qr-tag-list-container">
            <div className="qr-tag-list-container-title">
              <p> {t("choose qr tag for your pet")} </p>{" "}
            </div>{" "}
            <div className="qr-tag-list-inside-container">
              <a className="prev" onClick={() => handlePrev(qrTagSwiperRef)}>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                >
                  <path
                    fill="#0F0F0F"
                    d="M14.29 5.707a1 1 0 00-1.415 0L7.988 10.6a2 2 0 000 2.828l4.89 4.89a1 1 0 001.415-1.414l-4.186-4.185a1 1 0 010-1.415l4.182-4.182a1 1 0 000-1.414z"
                  ></path>{" "}
                </svg>{" "}
              </a>{" "}
              <a className="next" onClick={() => handleNext(qrTagSwiperRef)}>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                >
                  <path
                    fill="#0F0F0F"
                    d="M9.71 18.293a1 1 0 001.415 0l4.887-4.892a2 2 0 000-2.828l-4.89-4.89a1 1 0 00-1.415 1.414l4.186 4.185a1 1 0 010 1.415L9.71 16.879a1 1 0 000 1.414z"
                  ></path>{" "}
                </svg>{" "}
              </a>{" "}
              <Swiper
                ref={qrTagSwiperRef}
                wrapperClass="cards-swiper-container"
                spaceBetween={25}
                breakpoints={{
                  0: {
                    slidesPerView: 1,
                  },
                  768: {
                    slidesPerView: 3,
                  },
                  1023: {
                    slidesPerView: 5,
                  },
                  1024: {
                    slidesPerView: 5,
                  },
                  1281: {
                    slidesPerView: 5,
                  },
                  1601: {
                    slidesPerView: 5,
                  },
                }}
              >
                <SwiperSlide>
                  <div className="qr-tag-card">
                    <div className="qr-tag-card-imgbox">
                      <img className="front-img" src="img/5.png" alt="" />
                      <img className="back-img" src="img/front-qr.jpg" alt="" />
                    </div>{" "}
                    <p className="qr-tag-name"> {t("black")} </p>{" "}
                    <p className="qr-tag-price"> 50 {t("gel")} </p>{" "}
                  </div>{" "}
                </SwiperSlide>{" "}
                <SwiperSlide>
                  <div className="qr-tag-card">
                    <div className="qr-tag-card-imgbox">
                      <img className="front-img" src="img/5.png" alt="" />
                      <img className="back-img" src="img/front-qr.jpg" alt="" />
                    </div>{" "}
                    <p className="qr-tag-name"> {t("orange")} </p>{" "}
                    <p className="qr-tag-price"> 50 {t("gel")} </p>{" "}
                  </div>{" "}
                </SwiperSlide>{" "}
                <SwiperSlide>
                  <div className="qr-tag-card">
                    <div className="qr-tag-card-imgbox">
                      <img className="front-img" src="img/5.png" alt="" />
                      <img className="back-img" src="img/front-qr.jpg" alt="" />
                    </div>{" "}
                    <p className="qr-tag-name"> {t("cream")} </p>{" "}
                    <p className="qr-tag-price"> 50 {t("gel")} </p>{" "}
                  </div>{" "}
                </SwiperSlide>{" "}
                <SwiperSlide>
                  <div className="qr-tag-card">
                    <div className="qr-tag-card-imgbox">
                      <img className="front-img" src="img/5.png" alt="" />
                      <img className="back-img" src="img/front-qr.jpg" alt="" />
                    </div>{" "}
                    <p className="qr-tag-name"> {t("pink")} </p>{" "}
                    <p className="qr-tag-price"> 50 {t("gel")} </p>{" "}
                  </div>{" "}
                </SwiperSlide>{" "}
                <SwiperSlide>
                  <div className="qr-tag-card">
                    <div className="qr-tag-card-imgbox">
                      <img className="front-img" src="img/5.png" alt="" />
                      <img className="back-img" src="img/front-qr.jpg" alt="" />
                    </div>{" "}
                    <p className="qr-tag-name"> {t("light blue")} </p>{" "}
                    <p className="qr-tag-price"> 50 {t("gel")} </p>{" "}
                  </div>{" "}
                </SwiperSlide>{" "}
              </Swiper>{" "}
            </div>{" "}
            <div className="qr-tag-list-container-view-all">
              <Link to="/qrcollar"> {t("how it works")} </Link>{" "}
            </div>{" "}
          </div>{" "}
        </div>{" "}
        <CustomQr />
        <div className="parent-container-short">
          <div className="qr-and-gps-tags">
            <div className="qr-and-gps-tags-inside-container">
              <div className="tag-container container-1">
                <div className="tag-container-overlay">
                  <p> {t("metal tag")} </p>{" "}
                </div>{" "}
                <img src="../img/5.png" alt="" />
              </div>{" "}
              <div className="tag-container container-2">
                <div className="tag-container-overlay">
                  <p> {t("gps tracker")} </p>{" "}
                </div>{" "}
                <img src="../img/6.png" alt="" />
              </div>{" "}
              <div className="tag-container container-3">
                <div className="tag-container-overlay">
                  <p> {t("gps live locator")} </p>{" "}
                </div>{" "}
                <img src="../img/8.png" alt="" />
              </div>{" "}
            </div>{" "}
          </div>{" "}
          {/* <Filter /> */} {renderMainFilter()}{" "}
          <div className="main-container">
            <div className="left-container">
              <div className="vip-post-container">
                <div className="vip-post-container-header">
                  <span>
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 32 32">
                      <path d="M18 29h-4c-1.1 0-2-.9-2-2V15H8.4c-.8 0-1.5-.5-1.8-1.2s-.2-1.6.4-2.2l8.3-8.3c.4-.4 1-.4 1.4 0l8.3 8.3c.6.6.7 1.4.4 2.2s-1 1.2-1.8 1.2H20v12c0 1.1-.9 2-2 2z" />
                    </svg>{" "}
                  </span>{" "}
                  <p> VIP </p>{" "}
                  <div className="next-prev">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      className="prev"
                      onClick={() => handlePrev(VIPSwiperRef)}
                      viewBox="0 0 24 24"
                    >
                      <g
                        stroke="#292D32"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth="1.5"
                      >
                        <path d="M12 22c5.523 0 10-4.477 10-10S17.523 2 12 2 2 6.477 2 12s4.477 10 10 10zM15.5 12h-6">
                          {" "}
                        </path>{" "}
                        <path d="M11.5 9l-3 3 3 3"> </path>{" "}
                      </g>{" "}
                    </svg>{" "}
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      className="next"
                      onClick={() => handleNext(VIPSwiperRef)}
                      viewBox="0 0 24 24"
                    >
                      <g
                        stroke="#292D32"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth="1.5"
                      >
                        <path d="M12 22c5.523 0 10-4.477 10-10S17.523 2 12 2 2 6.477 2 12s4.477 10 10 10zM8.5 12h6">
                          {" "}
                        </path>{" "}
                        <path d="M12.5 15l3-3-3-3"> </path>{" "}
                      </g>{" "}
                    </svg>{" "}
                  </div>{" "}
                </div>{" "}
                <div className="vip-inside-cards-container">
                  <Swiper
                    ref={VIPSwiperRef}
                    wrapperClass="cards-swiper-container"
                    spaceBetween={15}
                    breakpoints={{
                      0: {
                        slidesPerView: 1,
                      },
                      768: {
                        slidesPerView: 3,
                      },
                      1023: {
                        slidesPerView: 4,
                      },
                      1024: {
                        slidesPerView: 4,
                      },
                      1281: {
                        slidesPerView: 5,
                      },
                      1601: {
                        slidesPerView: 5,
                      },
                    }}
                  >
                    {renderPosts("vip_posts")}{" "}
                  </Swiper>{" "}
                </div>{" "}
              </div>{" "}
              <Banner />
              <div className="missing-post-main-container">
                <div className="left-container-posts-header">
                  <span className="dep-lost">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      className="department-icon"
                      viewBox="0 0 24 24"
                    >
                      <path
                        stroke="#fff"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth={2}
                        d="M22 8v4M10.25 5.5H6.8c-1.68 0-2.52 0-3.162.327a3 3 0 0 0-1.311 1.31C2 7.78 2 8.62 2 10.3v1.2c0 .932 0 1.398.152 1.765a2 2 0 0 0 1.083 1.083c.367.152.833.152 1.765.152v4.25c0 .232 0 .348.01.446a2 2 0 0 0 1.794 1.794c.098.01.214.01.446.01s.348 0 .446-.01a2 2 0 0 0 1.794-1.794c.01-.098.01-.214.01-.446V14.5h.75c1.766 0 3.927.947 5.594 1.856.973.53 1.46.795 1.778.756a.946.946 0 0 0 .691-.411c.187-.26.187-.783.187-1.827V5.126c0-1.044 0-1.566-.187-1.827a.946.946 0 0 0-.691-.411c-.319-.039-.805.226-1.778.756-1.667.909-3.828 1.856-5.594 1.856Z"
                      />
                    </svg>{" "}
                  </span>{" "}
                  <p> {t("missing")} </p>{" "}
                  <div className="next-prev">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      className="prev"
                      onClick={() => handlePrev(missingSwiperRef)}
                      viewBox="0 0 24 24"
                    >
                      <g
                        stroke="#292D32"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth="1.5"
                      >
                        <path d="M12 22c5.523 0 10-4.477 10-10S17.523 2 12 2 2 6.477 2 12s4.477 10 10 10zM15.5 12h-6">
                          {" "}
                        </path>{" "}
                        <path d="M11.5 9l-3 3 3 3"> </path>{" "}
                      </g>{" "}
                    </svg>{" "}
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      className="next"
                      onClick={() => handleNext(missingSwiperRef)}
                      viewBox="0 0 24 24"
                    >
                      <g
                        stroke="#292D32"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth="1.5"
                      >
                        <path d="M12 22c5.523 0 10-4.477 10-10S17.523 2 12 2 2 6.477 2 12s4.477 10 10 10zM8.5 12h6">
                          {" "}
                        </path>{" "}
                        <path d="M12.5 15l3-3-3-3"> </path>{" "}
                      </g>{" "}
                    </svg>{" "}
                  </div>{" "}
                </div>{" "}
                <div className="missing-card-container">
                  <Swiper
                    ref={missingSwiperRef}
                    wrapperClass="cards-swiper-container"
                    spaceBetween={15}
                    breakpoints={{
                      0: {
                        slidesPerView: 1,
                      },
                      768: {
                        slidesPerView: 3,
                      },
                      1023: {
                        slidesPerView: 4,
                      },
                      1024: {
                        slidesPerView: 4,
                      },
                      1281: {
                        slidesPerView: 5,
                      },
                      1601: {
                        slidesPerView: 5,
                      },
                    }}
                  >
                    {renderPosts("missing_posts")}{" "}
                  </Swiper>{" "}
                </div>{" "}
              </div>{" "}
              <div className="founded-post-main-container">
                <div className="left-container-posts-header">
                  <span className="dep-found">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      className="department-icon"
                      viewBox="0 0 24 24"
                    >
                      <path
                        stroke="#fff"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth={2}
                        d="M22 8v4M10.25 5.5H6.8c-1.68 0-2.52 0-3.162.327a3 3 0 0 0-1.311 1.31C2 7.78 2 8.62 2 10.3v1.2c0 .932 0 1.398.152 1.765a2 2 0 0 0 1.083 1.083c.367.152.833.152 1.765.152v4.25c0 .232 0 .348.01.446a2 2 0 0 0 1.794 1.794c.098.01.214.01.446.01s.348 0 .446-.01a2 2 0 0 0 1.794-1.794c.01-.098.01-.214.01-.446V14.5h.75c1.766 0 3.927.947 5.594 1.856.973.53 1.46.795 1.778.756a.946.946 0 0 0 .691-.411c.187-.26.187-.783.187-1.827V5.126c0-1.044 0-1.566-.187-1.827a.946.946 0 0 0-.691-.411c-.319-.039-.805.226-1.778.756-1.667.909-3.828 1.856-5.594 1.856Z"
                      />
                    </svg>{" "}
                  </span>{" "}
                  <p> {t("found")} </p>{" "}
                  <div className="next-prev">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      className="prev"
                      onClick={() => handlePrev(foundSwiperRef)}
                      viewBox="0 0 24 24"
                    >
                      <g
                        stroke="#292D32"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth="1.5"
                      >
                        <path d="M12 22c5.523 0 10-4.477 10-10S17.523 2 12 2 2 6.477 2 12s4.477 10 10 10zM15.5 12h-6">
                          {" "}
                        </path>{" "}
                        <path d="M11.5 9l-3 3 3 3"> </path>{" "}
                      </g>{" "}
                    </svg>{" "}
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      className="next"
                      onClick={() => handleNext(foundSwiperRef)}
                      viewBox="0 0 24 24"
                    >
                      <g
                        stroke="#292D32"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth="1.5"
                      >
                        <path d="M12 22c5.523 0 10-4.477 10-10S17.523 2 12 2 2 6.477 2 12s4.477 10 10 10zM8.5 12h6">
                          {" "}
                        </path>{" "}
                        <path d="M12.5 15l3-3-3-3"> </path>{" "}
                      </g>{" "}
                    </svg>{" "}
                  </div>{" "}
                </div>{" "}
                <div className="founded-card-container">
                  <Swiper
                    ref={foundSwiperRef}
                    wrapperClass="cards-swiper-container"
                    spaceBetween={15}
                    breakpoints={{
                      0: {
                        slidesPerView: 1,
                      },
                      768: {
                        slidesPerView: 3,
                      },
                      1023: {
                        slidesPerView: 4,
                      },
                      1024: {
                        slidesPerView: 4,
                      },
                      1281: {
                        slidesPerView: 5,
                      },
                      1601: {
                        slidesPerView: 5,
                      },
                    }}
                  >
                    {renderPosts("found_posts")}{" "}
                  </Swiper>{" "}
                </div>{" "}
              </div>{" "}
              {stickyBanner ? (
                <div className="sticky-banner-container2">
                  <a href={stickyBanner.url} target="_blank">
                    <img src={stickyBanner.banner_img} alt="sticky-banner" />
                  </a>{" "}
                </div>
              ) : (
                <> </>
              )}{" "}
              <div className="recently-added-container">
                <div className="left-container-posts-header">
                  {" "}
                  {/* <span className="dep-last-post">
                                                                                                                                                                                                                                                                                                                                                        <svg
                                                                                                                                                                                                                                                                                                                                                          xmlns="http://www.w3.org/2000/svg"
                                                                                                                                                                                                                                                                                                                                                          viewBox="0 0 24 24"
                                                                                                                                                                                                                                                                                                                                                          className="department-icon"
                                                                                                                                                                                                                                                                                                                                                        >
                                                                                                                                                                                                                                                                                                                                                          <g fill="#fff">
                                                                                                                                                                                                                                                                                                                                                            <path d="M22 12c0 5.523-4.477 10-10 10S2 17.523 2 12 6.477 2 12 2s10 4.477 10 10Z" />
                                                                                                                                                                                                                                                                                                                                                            <path
                                                                                                                                                                                                                                                                                                                                                              fillRule="evenodd"
                                                                                                                                                                                                                                                                                                                                                              d="M12 7.25a.75.75 0 0 1 .75.75v3.69l2.28 2.28a.75.75 0 1 1-1.06 1.06l-2.5-2.5a.75.75 0 0 1-.22-.53V8a.75.75 0 0 1 .75-.75Z"
                                                                                                                                                                                                                                                                                                                                                              clipRule="evenodd"
                                                                                                                                                                                                                                                                                                                                                            />
                                                                                                                                                                                                                                                                                                                                                          </g>
                                                                                                                                                                                                                                                                                                                                                        </svg>
                                                                                                                                                                                                                                                                                                                                                      </span> */}{" "}
                  <p> {t("recently added")} </p>{" "}
                </div>{" "}
                <div className="recently-added-inside-container">
                  <Swiper
                    breakpoints={{
                      0: {
                        slidesPerView: 1,
                      },
                      768: {
                        slidesPerView: 3,
                      },
                      1023: {
                        slidesPerView: 4,
                      },
                      1024: {
                        slidesPerView: 4,
                      },
                      1281: {
                        slidesPerView: 5,
                      },
                      1601: {
                        slidesPerView: 5,
                      },
                    }}
                    grid={{
                      rows: 7,
                      fill: "row",
                    }}
                    spaceBetween={15}
                    modules={[Grid]}
                    className="mySwiper"
                  >
                    {renderPosts("latest_posts")}{" "}
                  </Swiper>{" "}
                </div>{" "}
              </div>{" "}
            </div>{" "}
            <RightContianer />
          </div>{" "}
        </div>{" "}
      </div>{" "}
      {/* <!--END MAIN CONTAINER--> */}{" "}
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    posts: state.posts,
    stickyBanner: state.management.stickyBanner,
    overallStatistics: state.orders.overallStatistics,
  };
};
export default connect(mapStateToProps, {
  getPostsAction,
  getOverallStatisticsAction,
})(Home);
