import React from "react";
import Image from "./Image";

import { useTranslation } from "react-i18next";

const AdPostCard = ({ data }) => {
  const { t } = useTranslation();
  return (
    <div className="card-3">
      <div className="company-img-box-and-name">
        <Image src={data.ad_post_img} />

        <div className="company-name">
          <p className="company-name">{data.name}</p>
          <span>
            <span>
              <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
                <path
                  stroke="#000"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth={2}
                  d="m6 12 4.243 4.243 8.484-8.486"
                />
              </svg>
              24 / 7
            </span>
            <span>
              <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
                <path
                  stroke="#000"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth={2}
                  d="m6 12 4.243 4.243 8.484-8.486"
                />
              </svg>
              {t("on call")}
            </span>
          </span>
        </div>
      </div>

      <hr />

      <div className="company-info-outside-container">
        <div className="company-info-container">
          <div className="company-info-inside-container">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="#000"
              version="1.1"
              viewBox="0 0 120 120"
              xmlSpace="preserve"
            >
              <path d="M60.5-.12c-24.778 0-44.865 20.087-44.865 44.865 0 41.909 44.865 75.405 44.865 75.405s44.865-33.494 44.865-75.405C105.365 19.966 85.277-.121 60.5-.12zm.001 64.688c-10.948 0-19.823-8.875-19.823-19.823s8.875-19.823 19.823-19.823 19.823 8.875 19.823 19.823-8.875 19.823-19.823 19.823z"></path>
            </svg>
            <p>{data.address}</p>
          </div>

          <div className="company-info-inside-container">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
            >
              <path
                fill="#1C274C"
                d="M10.038 5.316l.649 1.163c.585 1.05.35 2.426-.572 3.349 0 0-1.12 1.119.91 3.148 2.027 2.027 3.146.91 3.147.91.923-.923 2.3-1.158 3.349-.573l1.163.65c1.585.884 1.772 3.106.379 4.5-.837.836-1.863 1.488-2.996 1.53-1.908.073-5.149-.41-8.4-3.66-3.25-3.251-3.733-6.492-3.66-8.4.043-1.133.694-2.159 1.53-2.996 1.394-1.393 3.616-1.206 4.5.38z"
              ></path>
            </svg>
            <p>{data.phone_numbers}</p>
          </div>

          <div className="company-info-inside-container">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
            >
              <g>
                <path
                  fill="#5e5e5e"
                  d="M22 12c0 5.523-4.477 10-10 10S2 17.523 2 12 6.477 2 12 2s10 4.477 10 10z"
                ></path>
                <path
                  fill="#fff"
                  fillRule="evenodd"
                  d="M12 7.25a.75.75 0 01.75.75v3.69l2.28 2.28a.75.75 0 11-1.06 1.06l-2.5-2.5a.75.75 0 01-.22-.53V8a.75.75 0 01.75-.75z"
                  clipRule="evenodd"
                ></path>
              </g>
            </svg>
            <p>
              <span className="green">{t("open")}</span> {t(data.open_at)} ⋅{" "}
              {t("will close")} {t(data.close_at)} {t("am")}
            </p>
          </div>
        </div>
      </div>

      <div className="company-contact-button">
        <button>
          ვიზიტის დაჯავშნა
          <img src="../../svg/soon.svg" alt="" />
        </button>
      </div>
    </div>
  );
};

export default AdPostCard;
