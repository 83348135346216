import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { Link, useLocation } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { changeUserCurrency } from "../actions/authActions";

import useComponentVisible from "../hooks/useComponentVisible";

import {
  getCurrentUserData,
  logoutAction,
  changeSignupModalDisplay,
  changeLoginModalDisplayAciton,
} from "../actions/authActions";
import { getCurrancyRate, getSavedPostAction } from "../actions/postActions";
import { getToken } from "../utils";
import Login from "../pages/auth/Login";
import Image from "./Image";
import Signup from "../pages/auth/Signup";
import MenuForMobile from "./MenuForMobile";
import Banner from "./Banner";
import Logo from "./Logo";

const lngs = ["ka", "en"]; // "ru"
const lngsName = { ka: "ქართული", en: "English", ru: "Русский" };

const Header = ({
  getCurrentUserData,
  logoutAction,
  userData,
  changeSignupModalDisplay,
  getSavedPostAction,
  darkMode,
  setDarkMode,
  getCurrancyRate,
  loginModalDisplay,
  changeLoginModalDisplayAciton,
  changeUserCurrency,
  userCurrency,
}) => {
  const [isLogedIn, setIsLogedIn] = useState(null);
  const [langSwitcherRef, isLangSwicherVisalbe, setIsLangSwicherVisable] =
    useComponentVisible(false);
  const [userDropdownRef, isUserDropdownVisalbe, setIsUserDropdownVisable] =
    useComponentVisible(false);
  const [filterModalRef, isFilterModalVisalbe, setIsFilterModalVisable] =
    useComponentVisible("");
  const [
    institutionsDropDownRef,
    isInstitutionsDropDownVisalbe,
    setIsInstitutionsDropDownVisable,
  ] = useComponentVisible(false);
  const [modalSearchRef, isModalSearchVisalbe, setIsModalSearchVisable] =
    useComponentVisible("");
  const [menuForMobileRef, isMenuForMobileVisalbe, setIsMenuForMobileVisable] =
    useComponentVisible("");
  const { t, i18n } = useTranslation();
  const currentUrl = useLocation();
  const currentPathname = window.location.pathname;

  // useEffect(() => {
  //   let navMenu = document.querySelector(".header-middle-parent-container");

  //   if (currentPathname !== "/") {
  //     navMenu.classList.add("change");
  //   } else {
  //     navMenu.classList.remove("change");
  //   }
  // });
  useEffect(() => {
    getCurrancyRate();
  }, []);
  useEffect(() => {
    let header = document.getElementById("header");
    window.addEventListener("scroll", () => {
      if (window.scrollY > 25) {
        header.style.background = "#f4f4f4";
        header.style.boxShadow = "0px 2px 5px 0px rgba(0,0,0, 0.2)";
        // header.style.boxShadow = "rgba(149, 157, 165, 0.2) 0px 8px 24px";
      } else {
        header.style.background = "transparent";
        header.style.boxShadow = "none";
      }
    });
  });
  useEffect(() => {
    const token = getToken();
    if (token !== null && userData === null) {
      getCurrentUserData();
      setIsLogedIn(true);
      changeLoginModalDisplayAciton("none");
      getSavedPostAction();
    } else if (token !== null && userData !== null) {
      setIsLogedIn(true);
      changeLoginModalDisplayAciton("none");
    } else {
      setIsLogedIn(false);
    }
  }, [userData]);
  const renderHeader = () => {
    if (isLogedIn === true && userData) {
      return (
        <>
          <div
            className={
              "user-head-container " + (isUserDropdownVisalbe ? "active" : "")
            }
            onClick={() => setIsUserDropdownVisable(!isUserDropdownVisalbe)}
            ref={userDropdownRef}
            style={{ display: "flex" }}
          >
            <button>
              <div className="user-img-box">
                <Image src={userData.profile_pic} />
              </div>

              <svg
                className="logedin-user"
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                stroke="#000"
                strokeWidth="0.288"
                viewBox="0 0 24 24"
              >
                <g fill="#000" fillRule="evenodd" clipRule="evenodd">
                  <path d="M12 1.25a4.75 4.75 0 100 9.5 4.75 4.75 0 000-9.5zM8.75 6a3.25 3.25 0 116.5 0 3.25 3.25 0 01-6.5 0zM12 12.25c-2.313 0-4.445.526-6.024 1.414C4.42 14.54 3.25 15.866 3.25 17.5v.102c-.001 1.162-.002 2.62 1.277 3.662.629.512 1.51.877 2.7 1.117 1.192.242 2.747.369 4.773.369s3.58-.127 4.774-.369c1.19-.24 2.07-.605 2.7-1.117 1.279-1.042 1.277-2.5 1.276-3.662V17.5c0-1.634-1.17-2.96-2.725-3.836-1.58-.888-3.711-1.414-6.025-1.414zM4.75 17.5c0-.851.622-1.775 1.961-2.528 1.316-.74 3.184-1.222 5.29-1.222 2.104 0 3.972.482 5.288 1.222 1.34.753 1.961 1.677 1.961 2.528 0 1.308-.04 2.044-.724 2.6-.37.302-.99.597-2.05.811-1.057.214-2.502.339-4.476.339-1.974 0-3.42-.125-4.476-.339-1.06-.214-1.68-.509-2.05-.81-.684-.557-.724-1.293-.724-2.601z"></path>
                </g>
              </svg>

              <span className="user-profile-first-letters">
                <p>{userData.first_name.substring(0, 1).toUpperCase()}</p>
                {/* <p>{userData.last_name.substring(0, 1).toUpperCase()}</p> */}
              </span>

              <a className="user-fullname">{t(userData.first_name)}</a>

              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                className="user-arrow"
                viewBox="0 0 8 6"
              >
                <path
                  stroke="#BEBFC3"
                  strokeLinecap="round"
                  strokeWidth="1.5"
                  d="M1 1.5l3 3 3-3"
                ></path>
              </svg>
            </button>

            <div className="user-dropdown-container">
              <div className="close-dropdown-container-for-mobile">
                <svg
                  viewBox="0 0 24 24"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <g id="SVGRepo_bgCarrier" strokeWidth={0} />
                  <g
                    id="SVGRepo_tracerCarrier"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                  <g id="SVGRepo_iconCarrier">
                    <g clipPath="url(#clip0_429_11083)">
                      <path
                        d="M7 7.00006L17 17.0001M7 17.0001L17 7.00006"
                        stroke="#292929"
                        strokeWidth={2.5}
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                    </g>
                    <defs>
                      <clipPath id="clip0_429_11083">
                        <rect width={24} height={24} fill="white" />
                      </clipPath>
                    </defs>
                  </g>
                </svg>
              </div>
              <div className="user-info-mini-container">
                <Image src={userData.profile_pic} />
                <Link className="user-fullname">
                  {t(userData.first_name + " " + userData.last_name)}
                  <br />
                  <span>{t(`ID - ${userData.id}`)}</span>
                </Link>
              </div>

              <hr />

              <ul>
                <li>
                  <Link to="/add/new/post">{t("Add post")}</Link>
                </li>

                <li>
                  <Link to="/active/posts">{t("Active Posts")}</Link>
                </li>

                <li>
                  <Link to="/me">{t("My Profile")}</Link>
                </li>

                <li>
                  <Link to="/generateqr">{t("my qr tag")}</Link>
                </li>

                <li>
                  <Link to="/favorite">{t("Favorite")}</Link>
                </li>

                <li>
                  <Link to="messages">{t("Messages")}</Link>
                </li>

                <hr className="hr-2" />

                <li className="border-bottom">
                  <Link onClick={() => logoutAction()}>{t("logout")}</Link>
                </li>
              </ul>
            </div>
          </div>
        </>
      );
    }
    return (
      <>
        <div className="login-signup-container">
          <button onClick={() => changeLoginModalDisplayAciton("block")}>
            {/* <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              stroke="#000"
              strokeWidth="0.288"
              viewBox="0 0 24 24"
            >
              <g fill="#000" fillRule="evenodd" clipRule="evenodd">
                <path d="M12 1.25a4.75 4.75 0 100 9.5 4.75 4.75 0 000-9.5zM8.75 6a3.25 3.25 0 116.5 0 3.25 3.25 0 01-6.5 0zM12 12.25c-2.313 0-4.445.526-6.024 1.414C4.42 14.54 3.25 15.866 3.25 17.5v.102c-.001 1.162-.002 2.62 1.277 3.662.629.512 1.51.877 2.7 1.117 1.192.242 2.747.369 4.773.369s3.58-.127 4.774-.369c1.19-.24 2.07-.605 2.7-1.117 1.279-1.042 1.277-2.5 1.276-3.662V17.5c0-1.634-1.17-2.96-2.725-3.836-1.58-.888-3.711-1.414-6.025-1.414zM4.75 17.5c0-.851.622-1.775 1.961-2.528 1.316-.74 3.184-1.222 5.29-1.222 2.104 0 3.972.482 5.288 1.222 1.34.753 1.961 1.677 1.961 2.528 0 1.308-.04 2.044-.724 2.6-.37.302-.99.597-2.05.811-1.057.214-2.502.339-4.476.339-1.974 0-3.42-.125-4.476-.339-1.06-.214-1.68-.509-2.05-.81-.684-.557-.724-1.293-.724-2.601z"></path>
              </g>
            </svg> */}

            <span className="user-svg-container">
              <svg
                viewBox="0 0 24 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <g
                  stroke="#464455"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                >
                  <path d="M16 8a4 4 0 11-8 0 4 4 0 018 0zM14.682 14H9.318c-.483 0-.724 0-.94.046-.747.16-1.393.72-1.755 1.523-.104.232-.18.507-.333 1.059-.183.662-.275.993-.287 1.26-.044.943.465 1.794 1.221 2.042.215.07.505.07 1.084.07h7.384c.58 0 .87 0 1.084-.07.756-.248 1.265-1.1 1.221-2.042-.012-.267-.104-.598-.287-1.26-.153-.552-.229-.827-.334-1.059-.361-.803-1.007-1.363-1.754-1.523-.216-.046-.457-.046-.94-.046z" />
                </g>
              </svg>
            </span>

            <p>{t("login")}</p>
          </button>
        </div>

        <Login
          loginModalDisplay={loginModalDisplay}
          setLoginModalDisplay={changeLoginModalDisplayAciton}
        />
        <Signup />
      </>
    );
  };

  return (
    <>
      <div
        style={{ backgroundColor: "rgba(0,0,0,0.9)" }}
        id=""
        className={
          "modal-box-for-mobile " + (isMenuForMobileVisalbe ? "active" : "")
        }
      >
        <div className="modal-menu-for-mobile">
          <div className="close-modal-icon-box">
            <span className="close_mobilemenu">&times;</span>
          </div>

          <div className="close-dropdown-container-for-mobile">
            <svg
              viewBox="0 0 24 24"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <g id="SVGRepo_bgCarrier" strokeWidth={0} />
              <g
                id="SVGRepo_tracerCarrier"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
              <g id="SVGRepo_iconCarrier">
                <g clipPath="url(#clip0_429_11083)">
                  <path
                    d="M7 7.00006L17 17.0001M7 17.0001L17 7.00006"
                    stroke="#292929"
                    strokeWidth={2.5}
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                </g>
                <defs>
                  <clipPath id="clip0_429_11083">
                    <rect width={24} height={24} fill="black" />
                  </clipPath>
                </defs>
              </g>
            </svg>
          </div>

          <p>{t("menu")}</p>

          <div className="modal-menu-for-mobile-inside-container">
            <Link to="/">{t("home")}</Link>
            <Link to="/search/?department=for_sale">{t("buy")}</Link>
            <Link to="/search/?department=adopt">{t("adoption")}</Link>
            <Link to="/search/?department=missing">{t("missing")}</Link>
            <Link to="/search/?department=found">{t("found")}</Link>

            {/* <Link to="/ad/posts/clinic">{t("clinics")}</Link>
            <Link to="/ad/posts/grooming">{t("grooming")}</Link>
            <Link to="/ad/posts/hotel">{t("hotels")}</Link>
            <Link to="/ad/posts/shelter">{t("shelters")}</Link>

            <Link to="/workers">{t("petwalker / petsitter")}</Link> */}

            {/* <Link to="/ad/posts/transfer">{t("pet transfer")}</Link> */}

            <hr className="hr-for-mobile-menu" />

            <Link className="priority" to="order/qrcollar">
              {t("qrcollar")}
            </Link>
            {/* <Link className="active-link" to="/qrcollar">
            {t("how it works")}
          </Link> */}

            <hr className="hr-for-mobile-menu" />

            {/* <Link to="/aboutus">{t("about_us")}</Link> */}
            {/* <Link className="head-nav-contact" to="/contact">
            {t("contact")}
          </Link> */}
          </div>
        </div>
      </div>

      <div className="header-top-container">
        <div className="header-top-inside-container">
          <div className="header-left-container">
            <div className="social-icons">
              <a
                href="https://www.facebook.com/profile.php?id=61554431398309"
                target="_blank"
              >
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="-5 0 20 20">
                  <g>
                    <g fill="none" stroke="none">
                      <g fill="#5e5e5e" transform="translate(-385 -7399)">
                        <g transform="translate(56 160)">
                          <path d="M335.821 7259v-9h2.733l.446-4h-3.179v-1.948c0-1.03.027-2.052 1.466-2.052h1.458v-2.86c0-.043-1.253-.14-2.52-.14-2.645 0-4.302 1.657-4.302 4.7v2.3H329v4h2.923v9h3.898z"></path>
                        </g>
                      </g>
                    </g>
                  </g>
                </svg>
              </a>

              <a
                href="https://www.instagram.com/pethub_georgia/"
                target="_blank"
              >
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20">
                  <g>
                    <g fill="none" stroke="none">
                      <g fill="#5e5e5e" transform="translate(-340 -7439)">
                        <g transform="translate(56 160)">
                          <path d="M289.87 7279.123c-1.628.073-3.04.471-4.179 1.606-1.143 1.14-1.536 2.557-1.61 4.168-.045 1.005-.313 8.601.463 10.593a5.04 5.04 0 002.91 2.903c.634.246 1.356.412 2.416.461 8.86.401 12.145.183 13.53-3.364.246-.631.415-1.353.462-2.41.405-8.883-.066-10.809-1.61-12.351-1.225-1.222-2.666-2.054-12.382-1.606m.081 17.944c-.97-.043-1.496-.205-1.848-.341a3.255 3.255 0 01-1.888-1.883c-.591-1.514-.395-8.703-.342-9.866.051-1.14.282-2.18 1.086-2.985.995-.992 2.28-1.479 11.034-1.084 1.142.052 2.186.282 2.992 1.084.995.993 1.489 2.288 1.087 11.008-.044.968-.206 1.493-.342 1.843-.901 2.308-2.973 2.628-11.779 2.224m8.139-13.377c0 .657.534 1.19 1.194 1.19.66 0 1.195-.533 1.195-1.19a1.194 1.194 0 00-2.39 0m-9.226 5.298a5.103 5.103 0 005.11 5.097 5.103 5.103 0 005.109-5.097 5.102 5.102 0 00-5.11-5.096 5.102 5.102 0 00-5.11 5.096m1.794 0a3.313 3.313 0 013.316-3.308 3.313 3.313 0 013.317 3.308 3.313 3.313 0 01-3.317 3.31 3.313 3.313 0 01-3.316-3.31"></path>
                        </g>
                      </g>
                    </g>
                  </g>
                </svg>
              </a>

              {/* <a
                href="https://www.tiktok.com/@pethub_georgia?lang=en"
                target="_blank"
              >
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 32 32">
                  <path d="M16.656 1.029c1.637-.025 3.262-.012 4.886-.025a7.762 7.762 0 002.189 5.213l-.002-.002A8.77 8.77 0 0029 8.45l.028.002v5.036a13.327 13.327 0 01-5.331-1.247l.082.034a15.385 15.385 0 01-2.077-1.196l.052.034c-.012 3.649.012 7.298-.025 10.934a9.513 9.513 0 01-1.707 4.954l.02-.031c-1.652 2.366-4.328 3.919-7.371 4.011h-.014a9.071 9.071 0 01-5.139-1.31l.04.023C5.05 28.185 3.32 25.603 3 22.6l-.004-.041a23.163 23.163 0 01-.012-1.862c.49-4.779 4.494-8.476 9.361-8.476.547 0 1.083.047 1.604.136l-.056-.008c.025 1.849-.05 3.699-.05 5.548a4.29 4.29 0 00-5.465 2.619l-.009.03c-.133.427-.21.918-.21 1.426 0 .206.013.41.037.61l-.002-.024a4.26 4.26 0 004.382 3.586h-.009a4.198 4.198 0 003.451-1.994l.01-.018c.267-.372.45-.822.511-1.311l.001-.014c.125-2.237.075-4.461.087-6.698.012-5.036-.012-10.06.025-15.083z"></path>
                </svg>
              </a> */}
            </div>

            <Link className="top-aboutus" to="/aboutus">
              {t("about_us")}
            </Link>

            <Link className="top-order-collar" to="order/qrcollar">
              {t("our tags")}
            </Link>
          </div>

          <div className="header-right-info-container">
            <p className="">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
              >
                <g stroke="#1C274C" strokeLinecap="round" strokeWidth="1.5">
                  <path d="M14 2s2.2.2 5 3 3 5 3 5M14.207 5.536s.99.282 2.475 1.767c1.485 1.485 1.768 2.475 1.768 2.475M10.038 5.316l.649 1.163c.585 1.05.35 2.426-.572 3.349 0 0 0 0 0 0s-1.12 1.119.91 3.148c2.027 2.027 3.146.91 3.147.91 0 0 0 0 0 0 .923-.923 2.3-1.158 3.349-.573l1.163.65c1.585.884 1.772 3.106.379 4.5-.837.836-1.863 1.488-2.996 1.53-1.908.073-5.149-.41-8.4-3.66-3.25-3.251-3.733-6.492-3.66-8.4.043-1.133.694-2.159 1.53-2.996 1.394-1.393 3.616-1.206 4.5.38z"></path>
                </g>
              </svg>
              591 - 607 - 607
            </p>
            <p className="">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
              >
                <g stroke="#1C274C" strokeWidth="1.5">
                  <path d="M18 6.102c1.3.127 2.175.416 2.828 1.07C22 8.343 22 10.229 22 14c0 3.771 0 5.657-1.172 6.828C19.657 22 17.771 22 14 22h-4c-3.771 0-5.657 0-6.828-1.172C2 19.657 2 17.771 2 14c0-3.771 0-5.657 1.172-6.828C3.825 6.518 4.7 6.229 6 6.102"></path>
                  <path
                    strokeLinecap="round"
                    d="M10 6h4M11 9h2M8.159 11.8l-.72-.6c-.707-.59-1.061-.885-1.25-1.288C6 9.508 6 9.048 6 8.126V7c0-2.357 0-3.536.732-4.268C7.464 2 8.643 2 11 2h2c2.357 0 3.535 0 4.268.732C18 3.464 18 4.643 18 7v1.127c0 .92 0 1.381-.189 1.785-.189.403-.543.698-1.25 1.287l-.72.6c-1.836 1.53-2.755 2.296-3.841 2.296-1.086 0-2.004-.765-3.841-2.296z"
                  ></path>
                  <path
                    strokeLinecap="round"
                    d="M6 10l2.159 1.8c1.837 1.53 2.755 2.295 3.841 2.295 1.086 0 2.005-.765 3.841-2.296L18 10"
                  ></path>
                </g>
              </svg>
              Info@pethub.ge
            </p>
          </div>
        </div>
      </div>

      <div id="header" className="header-middle-parent-container">
        <div className="header-middle-inside-container">
          <div className="logo-box">
            <a href="http://pethub.ge">
              <Logo />
            </a>
          </div>

          <form className="header-search-container">
            <span className="select-category-button">All category</span>
            <input type="search" placeholder={t("search")} autoComplete />
            <input type="submit" />
          </form>

          <div className="header-middle-short-menu">
            <Link to="/">{t("home")}</Link>
            <Link to="/search/?department=for_sale">{t("buy")}</Link>
            <Link to="/search/?department=adopt">{t("adoption")}</Link>
            <Link to="/search/?department=missing">{t("missing")}</Link>
            <Link to="/search/?department=found">{t("found")}</Link>
            {/* <Link className="active-link" to="/qrcollar">
              {t("how it works")}
            </Link> */}
          </div>

          <div className="header-right-container">
            <span
              className={
                "menu-for-mobile " + (isMenuForMobileVisalbe ? "active" : "")
              }
              onClick={() => setIsMenuForMobileVisable(!isMenuForMobileVisalbe)}
              ref={menuForMobileRef}
            >
              <svg
                viewBox="0 0 24 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <g
                  stroke="#464455"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                >
                  <path d="M13 15c0-.943 0-1.414.293-1.707C13.586 13 14.057 13 15 13h2c.943 0 1.414 0 1.707.293.293.293.293.764.293 1.707v2c0 .943 0 1.414-.293 1.707C18.414 19 17.943 19 17 19h-2c-.943 0-1.414 0-1.707-.293C13 18.414 13 17.943 13 17v-2zM13 7c0-.943 0-1.414.293-1.707C13.586 5 14.057 5 15 5h2c.943 0 1.414 0 1.707.293C19 5.586 19 6.057 19 7v2c0 .943 0 1.414-.293 1.707C18.414 11 17.943 11 17 11h-2c-.943 0-1.414 0-1.707-.293C13 10.414 13 9.943 13 9V7zM5 15c0-.943 0-1.414.293-1.707C5.586 13 6.057 13 7 13h2c.943 0 1.414 0 1.707.293.293.293.293.764.293 1.707v2c0 .943 0 1.414-.293 1.707C10.414 19 9.943 19 9 19H7c-.943 0-1.414 0-1.707-.293C5 18.414 5 17.943 5 17v-2zM5 7c0-.943 0-1.414.293-1.707C5.586 5 6.057 5 7 5h2c.943 0 1.414 0 1.707.293C11 5.586 11 6.057 11 7v2c0 .943 0 1.414-.293 1.707C10.414 11 9.943 11 9 11H7c-.943 0-1.414 0-1.707-.293C5 10.414 5 9.943 5 9V7z" />
                </g>
              </svg>
            </span>

            {renderHeader()}

            <div className="language-and-darkmode">
              <div
                className={
                  "lang-switcher-container " +
                  (isLangSwicherVisalbe ? "active" : "")
                }
                onClick={() => setIsLangSwicherVisable(!isLangSwicherVisalbe)}
                ref={langSwitcherRef}
              >
                <button className="lang-list-btn">
                  <img src={`/img/${localStorage.getItem("i18nextLng")}.png`} />

                  <svg
                    className="earth"
                    viewBox="0 0 24 24"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      fill="none"
                      stroke="#000"
                      strokeWidth={1.3}
                      d="M12 23c6.075 0 11-4.925 11-11S18.075 1 12 1 1 5.925 1 12s4.925 11 11 11zm0 0c3 0 4-5 4-11S15 1 12 1 8 6 8 12s1 11 4 11zM2 16h20M2 8h20"
                    />
                  </svg>
                  <span>{lngsName[localStorage.getItem("i18nextLng")]}</span>
                  {/* <span>,</span>
                  <span>{userCurrency === "GEL" ? "₾" : "$"}</span> */}
                  <svg
                    className="language-arrow"
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 8 6"
                  >
                    <path
                      stroke="#BEBFC3"
                      strokeLinecap="round"
                      strokeWidth="1.5"
                      d="M1 1.5l3 3 3-3"
                    ></path>
                  </svg>
                </button>

                <div
                  className={
                    "lang-list-container " +
                    (isLangSwicherVisalbe ? "active" : "")
                  }
                >
                  <div className="close-dropdown-container-for-mobile">
                    <svg
                      viewBox="0 0 24 24"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <g id="SVGRepo_bgCarrier" strokeWidth={0} />
                      <g
                        id="SVGRepo_tracerCarrier"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                      <g id="SVGRepo_iconCarrier">
                        <g clipPath="url(#clip0_429_11083)">
                          <path
                            d="M7 7.00006L17 17.0001M7 17.0001L17 7.00006"
                            stroke="#292929"
                            strokeWidth={2.5}
                            strokeLinecap="round"
                            strokeLinejoin="round"
                          />
                        </g>
                        <defs>
                          <clipPath id="clip0_429_11083">
                            <rect width={24} height={24} fill="black" />
                          </clipPath>
                        </defs>
                      </g>
                    </svg>
                  </div>
                  <ul className="">
                    <h3>{t("language")}</h3>
                    {lngs.map((lng) => {
                      if (lng === localStorage.getItem("i18nextLng")) {
                        return;
                      } else {
                        return (
                          <>
                            <li
                              className="lang-list-button"
                              type="submit"
                              key={lng}
                              onClick={() => i18n.changeLanguage(lng)}
                              disabled={i18n.resolvedLanguage === lng}
                            >
                              <img src={`/img/${lng}.png`} />
                              <p>{lngsName[lng]}</p>
                            </li>
                          </>
                        );
                      }
                    })}
                  </ul>

                  <hr />

                  <ul id="currency" className="current-currency-for-mobile">
                    <h3>{t("currency")}</h3>
                    <li
                      className={
                        "current-currency " +
                        (userCurrency === "GEL" ? "active" : "")
                      }
                      onClick={() => {
                        changeUserCurrency("GEL");
                      }}
                    >
                      <input type="radio" />
                      <p>{t("GEL")}</p>-<p>₾</p>
                    </li>
                    <li
                      className={
                        "current-currency " +
                        (userCurrency === "USD" ? "active" : "")
                      }
                      onClick={() => {
                        changeUserCurrency("USD");
                      }}
                    >
                      <input type="radio" />
                      <p>{t("USD")}</p>-<p>$</p>
                    </li>
                  </ul>

                  <ul className="none">
                    <h3>{t("country")}</h3>
                    <li>
                      <img src="./img/ka.png" alt="" />
                      <p>{t("georgia")}</p>
                    </li>
                    <li>
                      <img src="./img/ru.png" alt="" />
                      <p>{t("Russia")}</p>
                    </li>
                    <li>
                      <img src="./img/arm.webp" alt="" />
                      <p>{t("Armenia")}</p>
                    </li>
                    <li>
                      <img src="./img/aze.png" alt="" />
                      <p>{t("Azerbaijan")}</p>
                    </li>
                    <li>
                      <img src="./img/tur.png" alt="" />
                      <p>{t("Turkey")}</p>
                    </li>
                  </ul>
                </div>
              </div>

              <div id="currency" className="header-currency">
                <span
                  className="currency-switch"
                  onClick={() =>
                    changeUserCurrency(userCurrency === "GEL" ? "USD" : "GEL")
                  }
                >
                  {userCurrency === "GEL" ? (
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      className="lari"
                      viewBox="0 0 24 24"
                    >
                      <path d="M20 20h-8.5A6.5 6.5 0 018 8v4a1 1 0 002 0V7.18A6.69 6.69 0 0111.5 7h.5v5a1 1 0 002 0V7.53a6.5 6.5 0 013.87 4.67 1 1 0 002-.4A8.53 8.53 0 0014 5.39V3a1 1 0 00-2 0v2h-.5a8.29 8.29 0 00-1.5.14V3a1 1 0 00-2 0v2.76A8.49 8.49 0 006 20H4a1 1 0 000 2h16a1 1 0 000-2z"></path>
                    </svg>
                  ) : (
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                    >
                      <path
                        fill="#000"
                        fillRule="evenodd"
                        d="M13 3.5a1 1 0 10-2 0v.56c-1.17.14-2.247.525-3.104 1.117C6.796 5.937 6 7.09 6 8.5c0 1.274.492 2.457 1.578 3.293.856.659 2.01 1.046 3.422 1.166v4.963c-.784-.126-1.46-.394-1.967-.745C8.323 16.687 8 16.09 8 15.5a1 1 0 10-2 0c0 1.41.796 2.563 1.896 3.323.857.592 1.934.978 3.104 1.118v.559a1 1 0 102 0v-.556c1.162-.134 2.238-.502 3.097-1.085C17.212 18.1 18 16.944 18 15.5c0-1.307-.486-2.498-1.584-3.329-.859-.65-2.012-1.018-3.416-1.132v-4.96c.784.125 1.46.393 1.967.744C15.677 7.313 16 7.91 16 8.5a1 1 0 102 0c0-1.41-.796-2.563-1.896-3.323C15.247 4.585 14.17 4.2 13 4.06V3.5zm-2 2.578c-.784.126-1.46.394-1.967.745C8.323 7.313 8 7.91 8 8.5c0 .726.258 1.293.797 1.707.446.343 1.15.631 2.203.743V6.078zm2 6.969v4.88c.791-.12 1.468-.38 1.974-.723C15.668 16.732 16 16.14 16 15.5c0-.777-.264-1.336-.79-1.734-.444-.335-1.148-.612-2.21-.719z"
                        clipRule="evenodd"
                      ></path>
                    </svg>
                  )}
                </span>
              </div>

              <button
                className={"dark-mode-btn " + (darkMode ? "active" : "")}
                onClick={() => {
                  setDarkMode(!darkMode);

                  if (localStorage.getItem("dark-mode")) {
                    localStorage.removeItem("dark-mode");
                    setDarkMode("");
                  } else {
                    localStorage.setItem("dark-mode", "dark-mode");
                    setDarkMode("dark-mode");
                  }
                }}
              >
                <svg
                  className="moon"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  stroke="#000"
                  strokeWidth="0.264"
                  viewBox="0 0 24 24"
                >
                  <path
                    fill="#000"
                    fillRule="evenodd"
                    d="M11.017 2.802a9.25 9.25 0 1010.181 10.181A7.25 7.25 0 1111.017 2.802zM1.25 12C1.25 6.063 6.063 1.25 12 1.25c.717 0 1.075.571 1.137 1.026.059.438-.103.995-.606 1.299a5.75 5.75 0 107.894 7.894c.304-.503.861-.665 1.299-.606.455.062 1.026.42 1.026 1.137 0 5.937-4.813 10.75-10.75 10.75S1.25 17.937 1.25 12z"
                    clipRule="evenodd"
                  ></path>
                </svg>
                <ion-icon name="sunny" class="sun"></ion-icon>
              </button>
            </div>
            <button
              className={
                "modal-search-button " + (isModalSearchVisalbe ? "active" : "")
              }
              onClick={() => setIsModalSearchVisable(!isModalSearchVisalbe)}
              // ref={modalSearchRef}
            >
              {/* {t("search")} */}
            </button>
          </div>
        </div>
      </div>

      <div className="head-nav" id="headnav">
        <div className="head-nav-inside-container">
          <Link to="/">{t("home")}</Link>
          <Link to="/search/?department=for_sale">{t("buy")}</Link>
          <Link to="/search/?department=adopt">{t("adoption")}</Link>
          <Link to="/search/?department=missing">{t("missing")}</Link>
          <Link to="/search/?department=found">{t("found")}</Link>
          <Link className="service-for-large-desktop" to="/workers">
            {t("petwalker / petsitter")}
          </Link>
          <Link className="service-for-large-desktop" to="/ad/posts/clinic">
            {t("clinics")}
          </Link>
          <Link className="service-for-large-desktop" to="/ad/posts/grooming">
            {t("grooming")}
          </Link>
          <Link className="service-for-large-desktop" to="/ad/posts/hotel">
            {t("hotels")}
          </Link>
          <Link className="service-for-large-desktop" to="/ad/posts/shelter">
            {t("shelters")}
          </Link>
          <div className="institutions-for-pad">
            <Link to="/ad/posts/clinic">{t("clinics")}</Link>
            <Link to="/ad/posts/grooming">{t("grooming")}</Link>
            <Link to="/ad/posts/hotel">{t("hotels")}</Link>
            <Link to="/ad/posts/shelter">{t("shelters")}</Link>
          </div>
          <div
            className={
              "institutions-dropdown-button-container " +
              (isInstitutionsDropDownVisalbe ? "active" : "")
            }
            onClick={() =>
              setIsInstitutionsDropDownVisable(!isInstitutionsDropDownVisalbe)
            }
            ref={institutionsDropDownRef}
          >
            <span className="institutions-dropdown-button">
              <svg
                className="more-svg"
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
              >
                <g>
                  <circle cx="20" cy="12" r="2"></circle>
                  <circle cx="12" cy="12" r="2"></circle>
                  <circle cx="4" cy="12" r="2"></circle>
                </g>
              </svg>

              {/* {t("service")} */}

              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                className="user-arrow"
                viewBox="0 0 8 6"
              >
                <path
                  stroke="#BEBFC3"
                  strokeLinecap="round"
                  strokeWidth="1.5"
                  d="M1 1.5l3 3 3-3"
                ></path>
              </svg>
            </span>
            <div className="institutions-dropdown-container">
              <Link to="/ad/posts/clinic">{t("clinics")}</Link>
              <Link to="/ad/posts/grooming">{t("grooming")}</Link>
              <Link to="/ad/posts/hotel">{t("hotels")}</Link>
              <Link to="/ad/posts/shelter">{t("shelters")}</Link>
              <Link to="/workers">{t("petwalker / petsitter")}</Link>
              <Link to="/ad/posts/transfer">{t("pet transfer")}</Link>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    userData: state.userData.userData,
    loginModalDisplay: state.userData.loginModalDisplay,
    userCurrency: state.userData.userCurrency,
  };
};

export default connect(mapStateToProps, {
  getCurrentUserData,
  logoutAction,
  changeSignupModalDisplay,
  getSavedPostAction,
  getCurrancyRate,
  changeLoginModalDisplayAciton,
  changeUserCurrency,
})(Header);
