import React from "react";
import ReactDOM from "react-dom";

import { createStore, applyMiddleware, compose } from "redux";

import { Provider } from "react-redux";
import ReduxThunk from "redux-thunk";

import reducers from "./reducers";
import router from "./router";

import { RouterProvider } from "react-router-dom";
import "./i18n";

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
const store = createStore(
  reducers,
  composeEnhancers(applyMiddleware(ReduxThunk))
);

ReactDOM.render(
  <React.Suspense
    fallback={
      // <div className="spinner">
      //   <img src="./img/loading.gif" alt="loading" />
      // </div>
      <div class="custom-loader"></div>
    }
  >
    <Provider store={store}>
      <RouterProvider router={router} />
    </Provider>
  </React.Suspense>,
  document.getElementById("root")
);
