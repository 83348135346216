import axiosInstance from "../axiosApi/api.js";
import { toast } from "react-toastify";
import router from "../router.js";
import { handleServerError } from "./errors.js";
import { generate_unique_file_names } from "../utils.js";

import {
  ADD_NEW_USER_RATE_ID,
  CHANGE_LOGIN_MODAL_DISPLAY,
  CHANGE_SIGNUP_MODAL_DISPLAY,
  CHANGE_USER_CURRENCY,
  CLEAR_ERRORS,
  GET_ALL_RATED_IDS,
  GET_USER_PROFILE_DATA,
  USER_DATA,
} from "./types";

export const signupAction = (formValues) => {
  return async (dispatch) => {
    try {
      await axiosInstance.post("/users/", formValues);
      dispatch({
        type: CHANGE_SIGNUP_MODAL_DISPLAY,
        payload: "none",
      });
      router.navigate("/after/signup", { replace: true });
    } catch (e) {
      handleServerError(dispatch, e);
    }
  };
};

export const signupArtistAction = (formValues) => {
  return async (dispatch) => {
    try {
      const files = formValues.profile_pic;
      formValues.profile_pic = generate_unique_file_names(
        formValues.profile_pic
      );
      const res = await axiosInstance.post("/users/artists/", formValues);
      await fetch(res.data.pre_signed_url, {
        method: "PUT",
        headers: {
          "Content-Type": "multipart/form-data",
        },
        body: files[0],
      });
      router.navigate("/after/signup", { replace: true });
    } catch (e) {
      handleServerError(dispatch, e);
    }
  };
};

export const loginAction = (formValues) => {
  return async (dispatch) => {
    try {
      const res = await axiosInstance.post("/users/token/", formValues);
      axiosInstance.defaults.headers["Authorization"] =
        "Bearer " + res.data.access;
      localStorage.setItem("access_token", res.data.access);
      localStorage.setItem("refresh_token", res.data.refresh);
      dispatch(getCurrentUserData());
      router.navigate(window.location.pathname, { replace: true });
    } catch (e) {
      handleServerError(dispatch, e);
    }
  };
};

export const getCurrentUserData = () => {
  return async (dispatch) => {
    try {
      const res = await axiosInstance.get("/users/me/");
      dispatch({
        type: USER_DATA,
        payload: res.data,
      });
    } catch (e) {
      handleServerError(dispatch, e);
    }
  };
};

export const logoutAction = () => {
  return async (dispatch) => {
    localStorage.removeItem("access_token");
    localStorage.removeItem("refresh_token");
    axiosInstance.defaults.headers["Authorization"] = null;
    dispatch({
      type: USER_DATA,
      payload: null,
    });
    window.location.replace("/");
  };
};

export const changeSignupModalDisplay = (state) => {
  return {
    type: CHANGE_SIGNUP_MODAL_DISPLAY,
    payload: state,
  };
};

export const changePasswordAction = (formValues) => {
  return async (dispatch) => {
    try {
      await axiosInstance.patch("/users/change/password/", formValues);
      dispatch({
        type: CLEAR_ERRORS,
      });
      toast.success("update was successful");
    } catch (e) {
      handleServerError(dispatch, e);
    }
  };
};

export const requestResetPasswordAction = (formValues) => {
  return async (dispatch) => {
    try {
      await axiosInstance.post("/users/request/reset/password/", formValues);
    } catch (e) {
      handleServerError(dispatch, e);
    }
  };
};

export const resetPasswordAction = (formValues, additionalData) => {
  return async (dispatch) => {
    try {
      const { uid64, token } = additionalData;
      await axiosInstance.post(
        `/users/reset/password/${uid64}/${token}/`,
        formValues
      );
      toast.success("update was successful");
      router.navigate("/", { replace: true });
    } catch (e) {
      handleServerError(dispatch, e);
    }
  };
};

export const getUserProfileDataAction = () => {
  return async (dispatch) => {
    try {
      const res = await axiosInstance.get("/users/profile/");
      dispatch({
        type: GET_USER_PROFILE_DATA,
        payload: res.data,
      });
    } catch (e) {
      handleServerError(dispatch, e);
    }
  };
};

export const changeUserCurrency = (Currency) => {
  return {
    type: CHANGE_USER_CURRENCY,
    payload: Currency,
  };
};

export const updateUserDataAction = (userData) => {
  return async (dispatch) => {
    try {
      let profile_pic = null;
      if (userData.profile_pic && typeof userData.profile_pic !== "string") {
        profile_pic = userData.profile_pic[0];
        userData.profile_pic = generate_unique_file_names(profile_pic, true);
      } else {
        delete userData.profile_pic;
      }
      const res = await axiosInstance.put("/users/profile/", userData);
      if (res.data.pre_signed_url !== null) {
        await fetch(res.data.pre_signed_url, {
          method: "PUT",
          headers: {
            "Content-Type": "multipart/form-data",
          },
          body: profile_pic,
        });
      }
      toast.success("update was successful");
    } catch (e) {
      handleServerError(dispatch, e);
    }
  };
};

export const changeLoginModalDisplayAciton = (payload) => {
  return {
    type: CHANGE_LOGIN_MODAL_DISPLAY,
    payload: payload,
  };
};

export const submitRatingAction = (values) => {
  return async (dispatch) => {
    try {
      const toast_id = toast.loading("Please wait");
      await axiosInstance.post("/users/rating/", values);
      dispatch({
        type: ADD_NEW_USER_RATE_ID,
        payload: values,
      });
      toast.update(toast_id, {
        render: "All is good",
        type: "success",
        isLoading: false,
        autoClose: true,
      });
    } catch (e) {
      handleServerError(dispatch, e);
    }
  };
};

export const getRatedUserIdsAction = () => {
  return async (dispatch) => {
    try {
      const res = await axiosInstance.get("/users/rating/");
      dispatch({
        type: GET_ALL_RATED_IDS,
        payload: res.data,
      });
    } catch (e) {
      handleServerError(dispatch, e);
    }
  };
};
