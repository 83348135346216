import React, { useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import { connect } from "react-redux";
import { getFindPetInfo, sendLocation } from "../actions/orderActions.js";
import { useTranslation } from "react-i18next";
import { Outlet } from "react-router-dom";
import { addNewChatUser, clearChatUsers } from "../actions/chatActions";
import router from "../router";
import Logo from "../components/Logo";

const lngs = ["ka", "en"]; //  "ru"
const lngsName = { ka: "ქართული", en: "English", ru: "Русский" };

const FindPet = ({
  getFindPetInfo,
  sendLocation,
  findPetInfo,
  addNewChatUser,
  clearChatUsers,
}) => {
  const { t, i18n } = useTranslation();
  const { qrID } = useParams();
  const [userLocation, setUserLocation] = useState(null);

  useEffect(() => {
    getFindPetInfo(qrID);
  }, []);

  const handleShareLocation = () => {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        (position) => {
          const { latitude, longitude } = position.coords;
          sendLocation(qrID, { latitude, longitude });
          setUserLocation({ latitude, longitude });
        },
        (error) => {
          alert("Error getting location: " + error.message);
        }
      );
    } else {
      alert("Geolocation is not supported by this browser.");
    }
  };

  if (findPetInfo === null) {
    return null;
  }
  if (findPetInfo !== null && findPetInfo.marked_as_lost === false) {
    return (
      <>
        <div className="binder">
          <p>Please use a mobile phone or tablet to use the page</p>
        </div>
        <div className="find-pet-info-container">
          <div className="find-pet-info-container-header">
            <div className="pet-photo-and-name-container">
              <div className="dropdown-menu-for-findpet-page">
                <button>
                  <svg
                    fill="#000000"
                    viewBox="0 0 32 32"
                    enableBackground="new 0 0 32 32"
                    id="Glyph"
                    xmlSpace="preserve"
                    xmlns="http://www.w3.org/2000/svg"
                    xmlnsXlink="http://www.w3.org/1999/xlink"
                  >
                    <g id="SVGRepo_bgCarrier" strokeWidth={0} />
                    <g
                      id="SVGRepo_tracerCarrier"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                    <g id="SVGRepo_iconCarrier">
                      <path
                        d="M16,13c-1.654,0-3,1.346-3,3s1.346,3,3,3s3-1.346,3-3S17.654,13,16,13z"
                        id="XMLID_287_"
                      />
                      <path
                        d="M6,13c-1.654,0-3,1.346-3,3s1.346,3,3,3s3-1.346,3-3S7.654,13,6,13z"
                        id="XMLID_289_"
                      />
                      <path
                        d="M26,13c-1.654,0-3,1.346-3,3s1.346,3,3,3s3-1.346,3-3S27.654,13,26,13z"
                        id="XMLID_291_"
                      />
                    </g>
                  </svg>

                  <div className="dropdown-menu-for-findpet-page-droped-box">
                    <Link to="/order/qrcollar"> {t("buy now")} </Link>{" "}
                    <Link className="active-link" to="/qrcollar">
                      {t("how it works")}
                    </Link>
                  </div>
                </button>

                <div className="find-pet-info-language-switcher">
                  {/* <img
                src={`/img/${localStorage.getItem("i18nextLng")}.png`}
                title=""
              /> */}
                  {lngs.map((lng) => {
                    if (lng === localStorage.getItem("i18nextLng")) {
                      return;
                    } else {
                      return (
                        <>
                          <img
                            type="submit"
                            key={lng}
                            onClick={() => i18n.changeLanguage(lng)}
                            disabled={i18n.resolvedLanguage === lng}
                            src={`/img/${lng}.png`}
                          />
                        </>
                      );
                    }
                  })}
                </div>
              </div>

              <img src={findPetInfo.pet_image} alt="Pet photo" />

              <p className="pet-name">{findPetInfo.pet_name}</p>

              <div className="find-pet-info-language-switcher">
                <img src={`/img/${localStorage.getItem("i18nextLng")}.png`} />
                {lngs.map((lng) => {
                  if (lng === localStorage.getItem("i18nextLng")) {
                    return;
                  } else {
                    return (
                      <>
                        <img
                          type="submit"
                          key={lng}
                          onClick={() => i18n.changeLanguage(lng)}
                          disabled={i18n.resolvedLanguage === lng}
                          src={`/img/${lng}.png`}
                        />
                      </>
                    );
                  }
                })}
              </div>
            </div>
          </div>

          <div className="find-pet-info-container-body">
            <div className="logo-button-container">
              <div className="logo-button">
                <Logo />
              </div>
              <div className="locked-buttons">
                <img className="" src="/svg/lock.svg" />
                <p>{t("information hidden")}</p>
              </div>
              <div className="locked-info">
                <img className="locked-info-svg" src="/svg/lock.svg" />
                <p className="locked-info-text">{t("information hidden")}</p>
              </div>
            </div>

            <div className="find-pet-info-container-locked-tbns">
              <Link className="" to="/aboutus">
                {t("about_us")}
              </Link>

              <Link className="" to="/">
                {t("download")} {t("app")}
              </Link>
            </div>
          </div>
        </div>
      </>
    );
  }
  return (
    <>
      <div className="binder">
        <p>Please use a mobile phone or tablet to use the page</p>
      </div>

      <div className="find-pet-info-container">
        <div className="find-pet-info-container-header">
          <div className="pet-photo-and-name-container">
            <div className="dropdown-menu-for-findpet-page">
              <button>
                <svg
                  fill="#000000"
                  viewBox="0 0 32 32"
                  enableBackground="new 0 0 32 32"
                  id="Glyph"
                  xmlSpace="preserve"
                  xmlns="http://www.w3.org/2000/svg"
                  xmlnsXlink="http://www.w3.org/1999/xlink"
                >
                  <g id="SVGRepo_bgCarrier" strokeWidth={0} />
                  <g
                    id="SVGRepo_tracerCarrier"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                  <g id="SVGRepo_iconCarrier">
                    <path
                      d="M16,13c-1.654,0-3,1.346-3,3s1.346,3,3,3s3-1.346,3-3S17.654,13,16,13z"
                      id="XMLID_287_"
                    />
                    <path
                      d="M6,13c-1.654,0-3,1.346-3,3s1.346,3,3,3s3-1.346,3-3S7.654,13,6,13z"
                      id="XMLID_289_"
                    />
                    <path
                      d="M26,13c-1.654,0-3,1.346-3,3s1.346,3,3,3s3-1.346,3-3S27.654,13,26,13z"
                      id="XMLID_291_"
                    />
                  </g>
                </svg>

                <div className="dropdown-menu-for-findpet-page-droped-box">
                  <Link to="/order/qrcollar"> {t("buy now")} </Link>{" "}
                  <Link className="active-link" to="/qrcollar">
                    {t("how it works")}
                  </Link>
                </div>
              </button>

              <div className="find-pet-info-language-switcher">
                {/* <img
                src={`/img/${localStorage.getItem("i18nextLng")}.png`}
                title=""
              /> */}
                {lngs.map((lng) => {
                  if (lng === localStorage.getItem("i18nextLng")) {
                    return;
                  } else {
                    return (
                      <>
                        <img
                          type="submit"
                          key={lng}
                          onClick={() => i18n.changeLanguage(lng)}
                          disabled={i18n.resolvedLanguage === lng}
                          src={`/img/${lng}.png`}
                        />
                      </>
                    );
                  }
                })}
              </div>
            </div>

            <img src={findPetInfo.pet_image} alt="Pet photo" />

            <p className="pet-name">{findPetInfo.pet_name}</p>
          </div>
        </div>

        <div className="find-pet-info-container-body">
          <div className="logo-button-container">
            <div className="logo-button">
              <Logo />
            </div>
          </div>

          <div className="find-pet-info-call-owner">
            <a className="call-owner" href={`tel:${findPetInfo.phone_number}`}>
              <svg xmlns="http://www.w3.org/2000/svg" viewBox="1.5 -1.5 24 24">
                <g>
                  <path d="M17.62 10.75a.77.77 0 01-.77-.77c0-.37-.37-1.14-.99-1.81-.61-.65-1.28-1.03-1.84-1.03a.77.77 0 01-.77-.77c0-.42.35-.77.77-.77 1 0 2.05.54 2.97 1.51.86.91 1.41 2.04 1.41 2.86 0 .43-.35.78-.78.78zM21.23 10.75a.77.77 0 01-.77-.77c0-3.55-2.89-6.43-6.43-6.43a.77.77 0 01-.77-.77c0-.42.34-.78.76-.78C18.42 2 22 5.58 22 9.98c0 .42-.35.77-.77.77z"></path>
                  <path d="M11.79 14.21l-3.27 3.27c-.36-.32-.71-.65-1.05-.99a28.414 28.414 0 01-2.79-3.27c-.82-1.14-1.48-2.28-1.96-3.41C2.24 8.67 2 7.58 2 6.54c0-.68.12-1.33.36-1.93.24-.61.62-1.17 1.15-1.67C4.15 2.31 4.85 2 5.59 2c.28 0 .56.06.81.18.26.12.49.3.67.56l2.32 3.27c.18.25.31.48.4.7.09.21.14.42.14.61 0 .24-.07.48-.21.71-.13.23-.32.47-.56.71l-.76.79c-.11.11-.16.24-.16.4 0 .08.01.15.03.23.03.08.06.14.08.2.18.33.49.76.93 1.28.45.52.93 1.05 1.45 1.58.36.35.71.69 1.06.99z"></path>
                  <path d="M21.97 18.33a2.54 2.54 0 01-.25 1.09c-.17.36-.39.7-.68 1.02-.49.54-1.03.93-1.64 1.18-.01 0-.02.01-.03.01-.59.24-1.23.37-1.92.37-1.02 0-2.11-.24-3.26-.73s-2.3-1.15-3.44-1.98c-.39-.29-.78-.58-1.15-.89l3.27-3.27c.28.21.53.37.74.48.05.02.11.05.18.08.08.03.16.04.25.04.17 0 .3-.06.41-.17l.76-.75c.25-.25.49-.44.72-.56.23-.14.46-.21.71-.21.19 0 .39.04.61.13.22.09.45.22.7.39l3.31 2.35c.26.18.44.39.55.64.1.25.16.5.16.78z"></path>
                </g>
              </svg>
              {/* {t("call the owner")} */}
            </a>
            <button
              className="message-owner"
              onClick={() => {
                clearChatUsers();
                addNewChatUser(findPetInfo.user);
                router.navigate("/messages", { replace: true });
              }}
            >
              <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
                <g>
                  <path d="M2 12.97V6.99C2 4.23 4.24 2 7 2h10c2.76 0 5 2.23 5 4.99v6.98c0 2.75-2.24 4.98-5 4.98h-1.5c-.31 0-.61.15-.8.4l-1.5 1.99c-.66.88-1.74.88-2.4 0l-1.5-1.99c-.16-.22-.52-.4-.8-.4H7c-2.76 0-5-2.23-5-4.98v-1z"></path>
                  <path d="M17 8.75H7c-.41 0-.75-.34-.75-.75s.34-.75.75-.75h10c.41 0 .75.34.75.75s-.34.75-.75.75zM13 13.75H7c-.41 0-.75-.34-.75-.75s.34-.75.75-.75h6c.41 0 .75.34.75.75s-.34.75-.75.75z"></path>
                </g>
              </svg>
              {/* {t("message")} */}
            </button>

            <button className="send-location" onClick={handleShareLocation}>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="4.7 2 17 17"
              >
                <path d="M16.205 5.265l-6.49 2.164h0c-1.634.544-2.45.816-2.776 1.129a2 2 0 000 2.884c.325.313 1.142.585 2.775 1.13h0c.33.11.494.164.64.241a2 2 0 01.833.833c.077.146.132.31.242.64.544 1.633.816 2.45 1.129 2.775a2 2 0 002.884 0c.313-.325.585-1.142 1.13-2.775h0l2.163-6.491c.552-1.656.828-2.484.391-2.921-.437-.437-1.265-.161-2.92.39z"></path>
              </svg>
              {/* {t("share location")} */}
            </button>

            {/* <button className="look-on-map">
              <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
                <g>
                  <path d="M19.88 20.94c-.95.7-2.2 1.06-3.69 1.06H7.81c-.24 0-.48-.01-.71-.04l6.9-6.9 5.88 5.88zM22 7.81v8.38c0 1.49-.36 2.74-1.06 3.69L15.06 14l6.9-6.9c.03.23.04.47.04.71z"></path>
                  <path d="M15.06 14l5.88 5.88c-.29.42-.64.77-1.06 1.06L14 15.06l-6.9 6.9c-.64-.04-1.22-.17-1.75-.37C3.21 20.81 2 18.91 2 16.19V7.81C2 4.17 4.17 2 7.81 2h8.38c2.72 0 4.62 1.21 5.4 3.35.2.53.33 1.11.37 1.75l-6.9 6.9z"></path>
                  <path d="M15.06 14l5.88 5.88c-.29.42-.64.77-1.06 1.06L14 15.06l-6.9 6.9c-.64-.04-1.22-.17-1.75-.37l.39-.39L21.59 5.35c.2.53.33 1.11.37 1.75l-6.9 6.9zM12.24 7.93c-.38-1.65-1.84-2.39-3.12-2.4-1.28 0-2.74.74-3.12 2.39-.42 1.83.7 3.36 1.71 4.32.4.38.9.56 1.41.56.51 0 1.01-.19 1.41-.56 1.01-.96 2.13-2.49 1.71-4.31zM9.15 9.49c-.55 0-1-.45-1-1s.44-1 1-1h.01c.55 0 1 .45 1 1s-.46 1-1.01 1z"></path>
                </g>
              </svg>
              {t("location")}
            </button> */}
          </div>

          <div className="find-pet-info-outside-container">
            <div className="find-pet-info">
              {/* <div className="find-pet-info-header">
                <span className="header-text">{t("owner")}</span>
              </div> */}
              <div className="label-container">
                <span>{t("name")}</span>
                <p>{t(findPetInfo.full_name)}</p>
              </div>
              <div className="label-container">
                <span>{t("phone_number")}</span>
                <p>{t(findPetInfo.phone_number)}</p>
              </div>
              <div className="label-container">
                <span>{t("city")}</span>
                <p>{t(findPetInfo.city)}</p>
              </div>
              <div className="label-container">
                <span>{t("address")}</span>
                <p className="border-none">{t(findPetInfo.address)}</p>
              </div>
            </div>

            <div className="find-pet-info none">
              <div className="find-pet-info-header">
                <span className="header-text">{t("pet info")}</span>
              </div>
              <div className="label-container pet-type">
                <span>{t("animal")}</span>
                <p>{t(findPetInfo.pet_type)}</p>
              </div>
              <div className="label-container">
                <span>{t("breed")}</span>
                <p>{t(findPetInfo.pet_breed)}</p>
              </div>
              <div className="label-container">
                <span>{t("gender")}</span>
                <p>{t(findPetInfo.gender)}</p>
              </div>
              <div className="label-container">
                <span>{t("pet color")}</span>
                <p>{t(findPetInfo.pet_color)}</p>
              </div>

              <div className="label-container">
                <span>{t("vaccinated")}</span>
                {findPetInfo.is_vaccinated ? (
                  <>
                    <p className="vaccinated">{t("vaccinated")}</p>
                  </>
                ) : (
                  <>
                    <p className="not-vaccinated">{t("not vaccinated")}</p>
                  </>
                )}
              </div>
            </div>

            <div className="find-pet-info">
              {/* <div className="find-pet-info-header">
                <span className="header-text">{t("additional info")}</span>
              </div> */}
              <div className="label-container">
                <span>{t("additional info")}</span>
                <p>{t(findPetInfo.comment)}</p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Outlet />
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    findPetInfo: state.orders.findPetInfo,
  };
};

export default connect(mapStateToProps, {
  getFindPetInfo,
  sendLocation,
  addNewChatUser,
  clearChatUsers,
})(FindPet);
