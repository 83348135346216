import React from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";

const AfterSignup = () => {
  const { t } = useTranslation();
  return (
    <div className="waiting-verification">
      <svg viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
        <g id="SVGRepo_bgCarrier" strokeWidth={0} />
        <g
          id="SVGRepo_tracerCarrier"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <g id="SVGRepo_iconCarrier">
          <path d="M11.4696 8.53039C11.6103 8.67104 11.801 8.75006 11.9999 8.75006C12.1989 8.75006 12.3896 8.67104 12.5303 8.53039L13.5303 7.53039C13.7448 7.31589 13.8089 6.9933 13.6928 6.71305C13.5768 6.43279 13.3033 6.25006 12.9999 6.25006H10.9999C10.6966 6.25006 10.4231 6.43279 10.307 6.71305C10.1909 6.9933 10.2551 7.31589 10.4696 7.53039L11.4696 8.53039Z" />
          <path d="M11.4696 15.4697C11.6103 15.3291 11.801 15.2501 11.9999 15.2501C12.1989 15.2501 12.3896 15.3291 12.5303 15.4697L13.5303 16.4697C13.7448 16.6842 13.8089 17.0068 13.6928 17.2871C13.5768 17.5673 13.3033 17.7501 12.9999 17.7501H10.9999C10.6966 17.7501 10.4231 17.5673 10.307 17.2871C10.1909 17.0068 10.2551 16.6842 10.4696 16.4697L11.4696 15.4697Z" />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M13.149 12.0001L13.3363 11.8387C15.3117 10.1367 16.9541 8.08285 18.1801 5.78153C18.6612 4.87846 18.0639 3.77738 17.0446 3.68826L15.8711 3.58565C13.2952 3.36045 10.7047 3.36045 8.12884 3.58565L6.9553 3.68826C5.93595 3.77738 5.33865 4.87846 5.81976 5.78154C7.04577 8.08285 8.6882 10.1367 10.6636 11.8387L10.8509 12.0001L10.6636 12.1614C8.6882 13.8634 7.04577 15.9173 5.81976 18.2186C5.33865 19.1217 5.93595 20.2227 6.9553 20.3119L8.12884 20.4145C10.7047 20.6397 13.2952 20.6397 15.8711 20.4145L17.0446 20.3119C18.0639 20.2227 18.6612 19.1217 18.1801 18.2186C16.9541 15.9173 15.3117 13.8634 13.3363 12.1614L13.149 12.0001ZM12.0036 11.0063C12.004 11.0062 12.0049 11.0058 12.0064 11.0045L12.3572 10.7023C14.1643 9.14531 15.6714 7.27135 16.8044 5.17298L15.7404 5.07995C13.2515 4.86235 10.7484 4.86235 8.25949 5.07996L7.19549 5.17298C8.32853 7.27135 9.83562 9.14531 11.6427 10.7023L11.9935 11.0045C11.9945 11.0053 11.9952 11.0058 11.9957 11.006C11.9959 11.0062 11.9955 11.006 11.9957 11.006C11.9964 11.0063 11.9983 11.0069 11.9999 11.0069C12.0016 11.0069 12.0028 11.0066 12.0036 11.0063ZM12.0064 12.9956C12.0049 12.9943 12.004 12.994 12.0036 12.9938C12.0032 12.9937 12.0028 12.9935 12.0022 12.9934C12.0016 12.9933 12.0008 12.9932 11.9999 12.9932C11.9983 12.9932 11.9971 12.9935 11.9963 12.9938C11.9959 12.994 11.995 12.9943 11.9935 12.9956L11.6427 13.2978C9.83562 14.8548 8.32853 16.7288 7.19549 18.8271L8.25949 18.9202C10.7484 19.1378 13.2515 19.1378 15.7404 18.9202L16.8044 18.8271C15.6714 16.7288 14.1643 14.8548 12.3572 13.2978L12.0064 12.9956Z"
          />
        </g>
      </svg>
      <span className="waiting-verification-message-container">
        <p className="waiting-verification-header-text">
          {t("success_signup_message_header")} . . .
        </p>
        <p className="waiting-verification-message-text">
          {t("success_signup_message")}
        </p>
        <Link className="waiting-verification-go-back" to="../">
          {t("home")}
        </Link>
      </span>
    </div>
  );
};

export default AfterSignup;
