import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { useTranslation } from "react-i18next";

import UserPanel from "../components/UserPanel";
import Image from "../components/Image";
import ChangePassword from "../components/ChangePassword";
import {
  getUserProfileDataAction,
  updateUserDataAction,
} from "../actions/authActions";
import { validatePhoneNumber } from "../validations";

const Profile = ({
  getUserProfileDataAction,
  userProfileData,
  updateUserDataAction,
  serverErrors,
}) => {
  const [previewImage, setPreviewImage] = useState();
  const UpdateProfileDataSchema = yup.object().shape({
    first_name: yup.string().required(),
    last_name: yup.string().required(),
    email: yup.string().email().required(),
    phone_number: yup
      .number()
      .required()
      .typeError("phone_number_type_error")
      .test({
        name: "phone_number",
        test: validatePhoneNumber,
      }),
    address: yup.string().required(),
    worker_type: yup.string().required(),
    profile_pic: yup.mixed().nullable(),
  });
  const {
    register,
    formState: { errors },
    handleSubmit,
    setValue,
    reset,
  } = useForm({
    mode: "onChange",
    defaultValues: userProfileData,
    resolver: yupResolver(UpdateProfileDataSchema),
  });
  console.log(errors);
  useEffect(() => {
    if (userProfileData) {
      reset(userProfileData);
    } else {
      getUserProfileDataAction();
    }
  }, [userProfileData]);
  const { t } = useTranslation();

  const renderProfileIMG = () => {
    if (previewImage) {
      return <img src={previewImage} alt="User Photo" />;
    } else if (userProfileData) {
      return <Image src={userProfileData.profile_pic} alt="User Photo" />;
    }
  };

  return (
    <div className="userpanel-container">
      <UserPanel />
      <div className="userpanel-screen-main-container">
        <div className="personal-info-container">
          {/* <p className="personal-info-header-container">
            {t("personal information")}
          </p> */}
          <form
            className="user-information"
            onSubmit={handleSubmit(updateUserDataAction)}
          >
            <div className="screen-user-photo">
              <div className="photo">{renderProfileIMG()}</div>

              <div className="upload-user-photo-btn">
                <label
                  for="files"
                  class="btn"
                  onClick={() =>
                    document.querySelector(".profile_pic_upload").click()
                  }
                >
                  {t("upload your photo")}
                </label>
                <input
                  className="profile_pic_upload"
                  type="file"
                  placeholder="atvite"
                  onChange={(e) => {
                    const files = e.target.files;
                    setValue("profile_pic", files);
                    setPreviewImage(URL.createObjectURL(files[0]));
                  }}
                />
                {errors.profile_pic && (
                  <a className="error-text">{t(errors.profile_pic?.message)}</a>
                )}
              </div>
            </div>
            <div className="user-information-inside-container">
              <div className="user-update-fields">
                <input {...register("first_name")} />
                {errors.first_name && (
                  <a className="error-text">
                    {t(errors.first_name?.message.key)}
                  </a>
                )}
                {serverErrors.first_name && (
                  <p className="help is-danger">{serverErrors.first_name}</p>
                )}
              </div>
              <div className="user-update-fields">
                <input {...register("last_name")} />
                {errors.last_name && (
                  <a className="error-text">
                    {t(errors.last_name?.message.key)}
                  </a>
                )}
                {serverErrors.last_name && (
                  <p className="help is-danger">{serverErrors.last_name}</p>
                )}
              </div>
              <div className="user-update-fields">
                <input {...register("email")} />
                {errors.email && (
                  <a className="error-text">{t(errors.email?.message.key)}</a>
                )}
                {serverErrors.email && (
                  <a className="help is-danger error-text">
                    {t(serverErrors.email)}
                  </a>
                )}
              </div>
              <div className="user-update-fields">
                <input {...register("phone_number")} />
                {errors.phone_number && (
                  <a className="error-text">
                    {t(errors.phone_number?.message)}
                  </a>
                )}
                {serverErrors.phone_number && (
                  <p>{serverErrors.phone_number}</p>
                )}
              </div>
              <div className="user-update-fields">
                <input {...register("address")} />
                {errors.address && (
                  <a className="error-text">{t(errors.address?.message.key)}</a>
                )}
                {serverErrors.address && <p>{serverErrors.address}</p>}
              </div>
              <div className="user-update-fields">
                <select {...register("worker_type")}>
                  <option value="NONE">{t("noone")}</option>

                  <option value="PET SITTER">{t("petsitter")}</option>

                  <option value="PET WALKER">{t("petwalker")}</option>

                  <option value="BOTH">{t("both")}</option>
                </select>
                {errors.worker_type && (
                  <a className="error-text">{t(errors.worker_type?.message)}</a>
                )}
                {serverErrors.worker_type && <p>{serverErrors.worker_type}</p>}
                {serverErrors.error && (
                  <p className="help is-danger">{t(serverErrors.error)}</p>
                )}
              </div>
              <input className="submit" type="submit" value={t("save")} />
            </div>
          </form>
          <ChangePassword />
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    userProfileData: state.userData.userProfileData,
    serverErrors: state.errors.errors,
  };
};

export default connect(mapStateToProps, {
  getUserProfileDataAction,
  updateUserDataAction,
})(Profile);
