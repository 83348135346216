import React, { useEffect, useState, useRef } from "react";
import { useParams } from "react-router-dom";
import { connect } from "react-redux";
import { useTranslation } from "react-i18next";

import { Swiper, SwiperSlide } from "swiper/react";

import { singlePostAction, updateSavedPostsIDs } from "../actions/postActions";
import { addNewChatUser, clearChatUsers } from "../actions/chatActions";
import {
  DELETE_SAVED_POST,
  GET_SINGLE_POST,
  SAVE_POST,
} from "../actions/types";
import useComponentVisible from "../hooks/useComponentVisible";
import PostCard from "../components/PostCard";
import Image from "../components/Image";
import ImageForPostCard from "../components/ImageForPostCard";
import ShareModal from "../components/ShareModal";
import router from "../router";
import ImageSliderHook from "../hooks/ImageSilderHook";
import { calculatePrice, formatDate } from "../utils.js";

const SinglePost = ({
  singlePostAction,
  postData,
  suggestionPosts,
  addNewChatUser,
  clearChatUsers,
  userCurrency,
  USD_RATE,
  savedPosts,
  updateSavedPostsIDs,
  stickyBanner,
}) => {
  const {
    currentImageIndex,
    setCurrentImageIndex,
    handleNextImage,
    handlePreviousImage,
  } = ImageSliderHook(postData !== null ? postData.images : []);
  const [postCurrency, setPostCurrency] = useState(userCurrency);
  const { postID } = useParams();
  const { t } = useTranslation();
  const [imageModalRef, isImageModalVisable, setIsImageModalVisable] =
    useComponentVisible("");
  const [shareModalRef, isShareModalVisable, setIsShareModalVisable] =
    useComponentVisible(false);

  const suggestionPostSwiperRef = useRef(null);

  useEffect(() => {
    singlePostAction(postID, "get", GET_SINGLE_POST);
  }, []);

  const renderImages = () => {
    if (postData.preview_img !== null) {
      return postData.images.map((item, i) => {
        return (
          <img
            key={item.image}
            src={item.image}
            alt="pet image"
            onClick={() => setCurrentImageIndex(i)}
          />
        );
      });
    }
  };

  const renderDepartmentForSinglePost = () => {
    if (
      postData.department === "for_sale" &&
      postData.price_with_deal === false
    ) {
      return (
        <div className="price">
          <p>
            {calculatePrice(
              postData.price_in_GEL,
              postData.price_in_USD,
              postData.price_with_deal,
              postCurrency,
              USD_RATE
            )}
          </p>

          <div id="currency" className="currency">
            <span
              className={
                "currency-switch " + (postCurrency === "GEL" ? "active" : "")
              }
              onClick={() => setPostCurrency("GEL")}
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                className="lari"
                viewBox="0 0 24 24"
              >
                <path d="M20 20h-8.5A6.5 6.5 0 018 8v4a1 1 0 002 0V7.18A6.69 6.69 0 0111.5 7h.5v5a1 1 0 002 0V7.53a6.5 6.5 0 013.87 4.67 1 1 0 002-.4A8.53 8.53 0 0014 5.39V3a1 1 0 00-2 0v2h-.5a8.29 8.29 0 00-1.5.14V3a1 1 0 00-2 0v2.76A8.49 8.49 0 006 20H4a1 1 0 000 2h16a1 1 0 000-2z"></path>
              </svg>
            </span>

            <span
              className={
                "currency-switch " + (postCurrency === "USD" ? "active" : "")
              }
              onClick={() => setPostCurrency("USD")}
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
              >
                <path
                  fill="#000"
                  fillRule="evenodd"
                  d="M13 3.5a1 1 0 10-2 0v.56c-1.17.14-2.247.525-3.104 1.117C6.796 5.937 6 7.09 6 8.5c0 1.274.492 2.457 1.578 3.293.856.659 2.01 1.046 3.422 1.166v4.963c-.784-.126-1.46-.394-1.967-.745C8.323 16.687 8 16.09 8 15.5a1 1 0 10-2 0c0 1.41.796 2.563 1.896 3.323.857.592 1.934.978 3.104 1.118v.559a1 1 0 102 0v-.556c1.162-.134 2.238-.502 3.097-1.085C17.212 18.1 18 16.944 18 15.5c0-1.307-.486-2.498-1.584-3.329-.859-.65-2.012-1.018-3.416-1.132v-4.96c.784.125 1.46.393 1.967.744C15.677 7.313 16 7.91 16 8.5a1 1 0 102 0c0-1.41-.796-2.563-1.896-3.323C15.247 4.585 14.17 4.2 13 4.06V3.5zm-2 2.578c-.784.126-1.46.394-1.967.745C8.323 7.313 8 7.91 8 8.5c0 .726.258 1.293.797 1.707.446.343 1.15.631 2.203.743V6.078zm2 6.969v4.88c.791-.12 1.468-.38 1.974-.723C15.668 16.732 16 16.14 16 15.5c0-.777-.264-1.336-.79-1.734-.444-.335-1.148-.612-2.21-.719z"
                  clipRule="evenodd"
                ></path>
              </svg>
            </span>
          </div>
        </div>
      );
    } else if (
      postData.department === "for_sale" &&
      postData.price_with_deal === true
    ) {
      return (
        <div className="price-with-deal">
          <p>{t("price with deal")}</p>
        </div>
      );
    } else if (postData.department === "found") {
      return (
        <span className="founded-singlepost">
          <p className="found">{t("found")}</p>
        </span>
      );
    } else if (postData.department === "missing") {
      return (
        <span className="missing-singlepost">
          <p className="missing">{t("missing")}</p>
        </span>
      );
    } else if (postData.department === "adopt") {
      return (
        <span className="adopt-singlepost">
          <p className="adopt">{t("adopt")}</p>
        </span>
      );
    }
  };

  const renderSuggestionPosts = () => {
    if (suggestionPosts) {
      return suggestionPosts.map((item) => {
        return (
          <SwiperSlide>
            <PostCard data={item} />
          </SwiperSlide>
        );
      });
    }
  };

  const handlePrev = (swiperRef) => {
    if (swiperRef.current) {
      swiperRef.current.swiper.slidePrev();
    }
  };

  const handleNext = (swiperRef) => {
    if (swiperRef.current) {
      swiperRef.current.swiper.slideNext();
    }
  };

  const renderPostType = () => {
    if (postData.post_type === "standard") {
      return null;
    }
    return <span className="post-status-container">{t("VIP")}</span>;
  };

  if (!postData) return null;
  return (
    <div className="post-page-main-container">
      <div
        ref={imageModalRef}
        className={"modal-for-img " + isImageModalVisable}
      >
        <div className="full-img-box">
          <span class="close-imgbox" onClick={() => setIsImageModalVisable("")}>
            {t("close modal")} <span>&times;</span>
          </span>
          <img
            className="modal-img"
            src={postData.images[currentImageIndex].image}
            alt=""
          />

          <a className="prev" onClick={handlePreviousImage}>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
            >
              <path
                fill="#0F0F0F"
                d="M14.29 5.707a1 1 0 00-1.415 0L7.988 10.6a2 2 0 000 2.828l4.89 4.89a1 1 0 001.415-1.414l-4.186-4.185a1 1 0 010-1.415l4.182-4.182a1 1 0 000-1.414z"
              ></path>
            </svg>
          </a>
          <a className="next" onClick={handleNextImage}>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
            >
              <path
                fill="#0F0F0F"
                d="M9.71 18.293a1 1 0 001.415 0l4.887-4.892a2 2 0 000-2.828l-4.89-4.89a1 1 0 00-1.415 1.414l4.186 4.185a1 1 0 010 1.415L9.71 16.879a1 1 0 000 1.414z"
              ></path>
            </svg>
          </a>
        </div>
      </div>
      <div className="post-page-header">
        <div className="post-title-container">
          <p>{t(postData.breed)}</p>
        </div>

        <div className="city-view-id-container">
          <p>
            {t("georgia")}, {t(postData.city)}
          </p>
          <p>{formatDate(postData.created_at)}</p>
          <p>
            {postData.views} {t("views")}
          </p>
          <p className="view-post">ID - {postData.id}</p>
        </div>
      </div>

      <div className="post-img-info-container">
        <div class="post-img-and-info-container">
          <div className="post-page-img-gallery">
            <div className="slideshow-container">
              {renderPostType()}
              <span className="header-svg-container" ref={shareModalRef}>
                <span className="svg-inside-container">
                  <svg
                    className={savedPosts.includes(postData.id) ? "active" : ""}
                    onClick={() => {
                      if (savedPosts.includes(postData.id)) {
                        updateSavedPostsIDs(
                          postData.id,
                          "delete",
                          DELETE_SAVED_POST
                        );
                      } else {
                        updateSavedPostsIDs(postData.id, "post", SAVE_POST);
                      }
                    }}
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 512 512"
                  >
                    <rect fill="transparent" paintOrder="stroke" rx="30"></rect>
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 256 256"
                    >
                      <path d="M240 108a28 28 0 11-28-28 28 28 0 0128 28zm-168 0a28 28 0 10-28 28 28 28 0 0028-28zm20-20a28 28 0 10-28-28 28 28 0 0028 28zm72 0a28 28 0 10-28-28 28 28 0 0028 28zm23.12 60.86a35.3 35.3 0 01-16.87-21.14 44 44 0 00-84.5 0A35.25 35.25 0 0169 148.82 40 40 0 0088 224a39.48 39.48 0 0015.52-3.13 64.09 64.09 0 0148.87 0 40 40 0 0034.73-72z"></path>
                    </svg>
                  </svg>

                  <p className="title">{t("Save post")}</p>
                </span>

                {/* <span className="svg-inside-container none">
                  <svg
                    onClick={() => {
                      setIsShareModalVisable(!isShareModalVisable);
                    }}
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    className="share-link"
                    viewBox="0 0 24 24"
                  >
                    <g stroke="#1C274C" strokeWidth="1.5">
                      <path d="M9 12a2.5 2.5 0 11-5 0 2.5 2.5 0 015 0z"></path>
                      <path
                        strokeLinecap="round"
                        d="M14 6.5L9 10M14 17.5L9 14"
                      ></path>
                      <path d="M19 18.5a2.5 2.5 0 11-5 0 2.5 2.5 0 015 0zM19 5.5a2.5 2.5 0 11-5 0 2.5 2.5 0 015 0z"></path>
                    </g>
                  </svg>
                  <p className="title">{t("Links")}</p>
                </span> */}
                <ShareModal isVisible={isShareModalVisable} />
              </span>

              <img
                src={postData.images[currentImageIndex].image}
                alt="pet image"
                onClick={() => setIsImageModalVisable("active")}
              />

              <a className="prev" onClick={handlePreviousImage}>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                >
                  <path
                    fill="#0F0F0F"
                    d="M14.29 5.707a1 1 0 00-1.415 0L7.988 10.6a2 2 0 000 2.828l4.89 4.89a1 1 0 001.415-1.414l-4.186-4.185a1 1 0 010-1.415l4.182-4.182a1 1 0 000-1.414z"
                  ></path>
                </svg>
              </a>
              <a className="next" onClick={handleNextImage}>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                >
                  <path
                    fill="#0F0F0F"
                    d="M9.71 18.293a1 1 0 001.415 0l4.887-4.892a2 2 0 000-2.828l-4.89-4.89a1 1 0 00-1.415 1.414l4.186 4.185a1 1 0 010 1.415L9.71 16.879a1 1 0 000 1.414z"
                  ></path>
                </svg>
              </a>
            </div>

            <div className="img-list-container">{renderImages()}</div>
          </div>

          <div className="seller-info-container-static">
            <div className="seller-phone-message-outside-container">
              <div className="seller-profile">
                <div className="seller-photo">
                  <Image src={postData.user.profile_pic} />
                </div>

                <div className="seller-name-and-activeposts">
                  <p>{postData.user.first_name}</p>
                  <a href="">{t("all_posts")}</a>
                </div>
              </div>

              <div className="seller-phone">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                >
                  <g stroke="#1C274C" strokeLinecap="round" strokeWidth="1.5">
                    <path d="M14 2s2.2.2 5 3 3 5 3 5M14.207 5.536s.99.282 2.475 1.767c1.485 1.485 1.768 2.475 1.768 2.475M10.038 5.316l.649 1.163c.585 1.05.35 2.426-.572 3.349 0 0 0 0 0 0s-1.12 1.119.91 3.148c2.027 2.027 3.146.91 3.147.91 0 0 0 0 0 0 .923-.923 2.3-1.158 3.349-.573l1.163.65c1.585.884 1.772 3.106.379 4.5-.837.836-1.863 1.488-2.996 1.53-1.908.073-5.149-.41-8.4-3.66-3.25-3.251-3.733-6.492-3.66-8.4.043-1.133.694-2.159 1.53-2.996 1.394-1.393 3.616-1.206 4.5.38z"></path>
                  </g>
                </svg>

                <p href={`tel:${postData.phone_number}`}>
                  (+995) {postData.user.phone_number}
                </p>
              </div>

              <div
                className="send-message"
                onClick={() => {
                  clearChatUsers();
                  addNewChatUser(postData.user);
                  router.navigate("/messages", { replace: true });
                }}
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  className="message"
                  viewBox="0 0 24 24"
                >
                  <g stroke="#5E5E5E">
                    <path d="M8.5 10.5h7M7 18.43h4l4.45 2.96a.997.997 0 001.55-.83v-2.13c3 0 5-2 5-5v-6c0-3-2-5-5-5H7c-3 0-5 2-5 5v6c0 3 2 5 5 5z"></path>
                  </g>
                </svg>

                <p>{t("message")}</p>
              </div>
            </div>

            {renderDepartmentForSinglePost()}
          </div>

          <div className="post-description-and-info-main-container">
            <div className="post-pet-info-container">
              <div className="post-pet-head">
                <p>{t("basic_info")}</p>
              </div>

              <div className="post-pet-info">
                <label for="">
                  <p className="p1">{t("department")}</p>
                  <p className="p2">{t(postData.department)}</p>
                </label>

                <label for="">
                  <p className="p1">{t("animal")}</p>
                  <p className="p2">{t(postData.animal_type)}</p>
                </label>

                <label for="">
                  <p className="p1">{t("breed")}</p>
                  <p className="p2">{t(postData.breed)}</p>
                </label>

                <label for="">
                  <p className="p1">{t("gender")}</p>
                  <p className="p2">{t(postData.gender)}</p>
                </label>

                <label for="">
                  <p className="p1">{t("age")}</p>
                  <p className="p2">
                    {t(postData.age)} {t("month")}
                  </p>
                </label>

                <label for="">
                  <p className="p1">{t("documents")}</p>
                  <p className="p2">
                    {postData.documents ? t("yes") : t("no")}
                  </p>
                </label>
                <label for="">
                  <p className="p1">{t("vaccinated")}</p>
                  <p className="p2">
                    {t(postData.is_vaccinated ? t("yes") : t("no"))}
                  </p>
                </label>
              </div>
            </div>

            <div className="post-description-container">
              <div className="post-description-head">
                <p>{t("detail_info")}</p>
              </div>

              <div className="post-description">{postData.description}</div>
            </div>

            {stickyBanner ? (
              <div className="sticky-banner-container2">
                <a href={stickyBanner.url} target="_blank">
                  <img src={stickyBanner.banner_img} alt="sticky-banner" />
                </a>
              </div>
            ) : (
              <></>
            )}

            {/* <div className="post-pet-info-container">
              <div className="post-pet-head">
                <p>{t("basic_info")}</p>
              </div>

              <div className="post-pet-info">
                <label for="">
                  <p className="p1">{t("department")}</p>
                  <p className="p2">{t(postData.department)}</p>
                </label>

                <label for="">
                  <p className="p1">{t("animal")}</p>
                  <p className="p2">{t(postData.animal_type)}</p>
                </label>

                <label for="">
                  <p className="p1">{t("breed")}</p>
                  <p className="p2">{t(postData.breed)}</p>
                </label>

                <label for="">
                  <p className="p1">{t("gender")}</p>
                  <p className="p2">{t(postData.gender)}</p>
                </label>

                <label for="">
                  <p className="p1">{t("age")}</p>
                  <p className="p2">
                    {t(postData.age)} {t("month")}
                  </p>
                </label>

                <label for="">
                  <p className="p1">{t("documents")}</p>
                  <p className="p2">
                    {postData.documents ? t("yes") : t("no")}
                  </p>
                </label>
                <label for="">
                  <p className="p1">{t("vaccinated")}</p>
                  <p className="p2">
                    {t(
                      postData.is_vaccinated
                        ? t("yes (for vaccinated)")
                        : t("no")
                    )}
                  </p>
                </label>
              </div>
            </div> */}
          </div>
        </div>

        <div className="right-container-for-banner-in-single-post">
          <a href="" target="_blank">
            <img src="/img/banner4.png" alt="banner" />
          </a>

          <a href="" target="_blank">
            <img src="/img/banner5.png" alt="banner" />
          </a>
        </div>
      </div>

      <div className="single-post-additional-container">
        <div className="single-post-additional-container-header">
          {t("similar posts")}
          <div className="next-prev">
            <svg
              className="prev"
              onClick={() => handlePrev(suggestionPostSwiperRef)}
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
            >
              <g
                stroke="#292D32"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="1.5"
              >
                <path d="M12 22c5.523 0 10-4.477 10-10S17.523 2 12 2 2 6.477 2 12s4.477 10 10 10zM15.5 12h-6"></path>
                <path d="M11.5 9l-3 3 3 3"></path>
              </g>
            </svg>

            <svg
              className="next"
              onClick={() => handleNext(suggestionPostSwiperRef)}
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
            >
              <g
                stroke="#292D32"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="1.5"
              >
                <path d="M12 22c5.523 0 10-4.477 10-10S17.523 2 12 2 2 6.477 2 12s4.477 10 10 10zM8.5 12h6"></path>
                <path d="M12.5 15l3-3-3-3"></path>
              </g>
            </svg>

            {/* <a className="prev">
              ❮
            </a>
            <a className="next">
              ❯
            </a> */}
          </div>
        </div>

        <div className="similar-posts-container">
          <Swiper
            ref={suggestionPostSwiperRef}
            spaceBetween={15}
            breakpoints={{
              0: {
                slidesPerView: 1,
              },
              768: {
                slidesPerView: 2.8,
              },
              1023: {
                slidesPerView: 4,
              },
              1024: {
                slidesPerView: 4,
              },
              1281: {
                slidesPerView: 4,
              },
              1601: {
                slidesPerView: 4,
              },
            }}
          >
            {renderSuggestionPosts()}
          </Swiper>
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    postData: state.posts.single_post,
    suggestionPosts: state.posts.singlePostSuggestions,
    userCurrency: state.userData.userCurrency,
    USD_RATE: state.userData.USD_RATE,
    savedPosts: state.posts.savedPosts,
    stickyBanner: state.management.stickyBanner,
  };
};

export default connect(mapStateToProps, {
  singlePostAction,
  addNewChatUser,
  clearChatUsers,
  updateSavedPostsIDs,
})(SinglePost);
