import React from "react";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import ImageForPostCard from "./ImageForPostCard";
import { useTranslation } from "react-i18next";

import { updateSavedPostsIDs } from "../actions/postActions";
import { DELETE_SAVED_POST, SAVE_POST } from "../actions/types";
import PostCardImageContainer from "./PostCardImageContainer";

const FoundPostCard = ({ data, updateSavedPostsIDs, savedPosts }) => {
  const { t } = useTranslation();
  const renderSaveIcond = () => {
    if (savedPosts.includes(data.id)) {
      return (
        <svg
          className="save-button active"
          onClick={() =>
            updateSavedPostsIDs(data.id, "delete", DELETE_SAVED_POST)
          }
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 512 512"
        >
          <rect fill="transparent" paintOrder="stroke" rx="30"></rect>
          <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 256 256">
            <path d="M240 108a28 28 0 11-28-28 28 28 0 0128 28zm-168 0a28 28 0 10-28 28 28 28 0 0028-28zm20-20a28 28 0 10-28-28 28 28 0 0028 28zm72 0a28 28 0 10-28-28 28 28 0 0028 28zm23.12 60.86a35.3 35.3 0 01-16.87-21.14 44 44 0 00-84.5 0A35.25 35.25 0 0169 148.82 40 40 0 0088 224a39.48 39.48 0 0015.52-3.13 64.09 64.09 0 0148.87 0 40 40 0 0034.73-72z"></path>
          </svg>
        </svg>
      );
    }
    return (
      <svg
        className="save-button"
        onClick={() => updateSavedPostsIDs(data.id, "post", SAVE_POST)}
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 512 512"
      >
        <rect fill="transparent" paintOrder="stroke" rx="30"></rect>
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 256 256">
          <path d="M240 108a28 28 0 11-28-28 28 28 0 0128 28zm-168 0a28 28 0 10-28 28 28 28 0 0028-28zm20-20a28 28 0 10-28-28 28 28 0 0028 28zm72 0a28 28 0 10-28-28 28 28 0 0028 28zm23.12 60.86a35.3 35.3 0 01-16.87-21.14 44 44 0 00-84.5 0A35.25 35.25 0 0169 148.82 40 40 0 0088 224a39.48 39.48 0 0015.52-3.13 64.09 64.09 0 0148.87 0 40 40 0 0034.73-72z"></path>
        </svg>
      </svg>
    );
  };

  return (
    <div className="card">
      <div className="overlay-card-info-container for-founded-and-lost-containers">
        <div className="overlay-price">
          <span className="founded">{t("found")}</span>
        </div>

        <p className="missing-founded-phone">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
          >
            <path
              stroke="#292D32"
              strokeMiterlimit="10"
              strokeWidth="1.5"
              d="M21.97 18.33c0 .36-.08.73-.25 1.09-.17.36-.39.7-.68 1.02-.49.54-1.03.93-1.64 1.18-.6.25-1.25.38-1.95.38-1.02 0-2.11-.24-3.26-.73s-2.3-1.15-3.44-1.98a28.75 28.75 0 01-3.28-2.8 28.414 28.414 0 01-2.79-3.27c-.82-1.14-1.48-2.28-1.96-3.41C2.24 8.67 2 7.58 2 6.54c0-.68.12-1.33.36-1.93.24-.61.62-1.17 1.15-1.67C4.15 2.31 4.85 2 5.59 2c.28 0 .56.06.81.18.26.12.49.3.67.56l2.32 3.27c.18.25.31.48.4.7.09.21.14.42.14.61 0 .24-.07.48-.21.71-.13.23-.32.47-.56.71l-.76.79c-.11.11-.16.24-.16.4 0 .08.01.15.03.23.03.08.06.14.08.2.18.33.49.76.93 1.28.45.52.93 1.05 1.45 1.58.54.53 1.06 1.02 1.59 1.47.52.44.95.74 1.29.92.05.02.11.05.18.08.08.03.16.04.25.04.17 0 .3-.06.41-.17l.76-.75c.25-.25.49-.44.72-.56.23-.14.46-.21.71-.21.19 0 .39.04.61.13.22.09.45.22.7.39l3.31 2.35c.26.18.44.39.55.64.1.25.16.5.16.78z"
            ></path>
          </svg>
          {data.user_phone_number}
        </p>
      </div>

      <div className="slide-img-box-for-postcard">
        <Link to={`/post/${data.id}`}>
          <PostCardImageContainer
            data={data}
            ImageRenderer={ImageForPostCard}
          />
        </Link>
      </div>

      <div className="overlay-breed-and-save-container">
        <p className="overlay-breed-container">{t(data.breed)}</p>
        <div className="save-button-container">{renderSaveIcond()}</div>
      </div>

      <div className="card-info-container for-missing-and-found-cards">
        <div className="breed">
          <Link className="breed-text" to={`/post/${data.id}`}>
            {t(data.breed)}
          </Link>
          <div className="save-button-container">{renderSaveIcond()}</div>
        </div>

        <div className="age-city">
          <a className="gender">{t(data.gender)}</a>
          <a className="age">
            {data.age} {t("month")}
          </a>
          <a className="city">{t(data.city)}</a>
        </div>

        <div className="price">
          <span className="founded">{t("found")}</span>

          <div className="missing-founded-phone">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
            >
              <path
                stroke="#292D32"
                strokeMiterlimit="10"
                strokeWidth="1.5"
                d="M21.97 18.33c0 .36-.08.73-.25 1.09-.17.36-.39.7-.68 1.02-.49.54-1.03.93-1.64 1.18-.6.25-1.25.38-1.95.38-1.02 0-2.11-.24-3.26-.73s-2.3-1.15-3.44-1.98a28.75 28.75 0 01-3.28-2.8 28.414 28.414 0 01-2.79-3.27c-.82-1.14-1.48-2.28-1.96-3.41C2.24 8.67 2 7.58 2 6.54c0-.68.12-1.33.36-1.93.24-.61.62-1.17 1.15-1.67C4.15 2.31 4.85 2 5.59 2c.28 0 .56.06.81.18.26.12.49.3.67.56l2.32 3.27c.18.25.31.48.4.7.09.21.14.42.14.61 0 .24-.07.48-.21.71-.13.23-.32.47-.56.71l-.76.79c-.11.11-.16.24-.16.4 0 .08.01.15.03.23.03.08.06.14.08.2.18.33.49.76.93 1.28.45.52.93 1.05 1.45 1.58.54.53 1.06 1.02 1.59 1.47.52.44.95.74 1.29.92.05.02.11.05.18.08.08.03.16.04.25.04.17 0 .3-.06.41-.17l.76-.75c.25-.25.49-.44.72-.56.23-.14.46-.21.71-.21.19 0 .39.04.61.13.22.09.45.22.7.39l3.31 2.35c.26.18.44.39.55.64.1.25.16.5.16.78z"
              ></path>
            </svg>
            {data.user_phone_number}
          </div>
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    savedPosts: state.posts.savedPosts,
  };
};

export default connect(mapStateToProps, { updateSavedPostsIDs })(FoundPostCard);
