import React, { useState } from "react";
import Slider from "react-slick";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";

const customQrDesign = [
  "/img/design1.png",
  "/img/design2.png",
  "/img/design8.png",
  "/img/design5.png",
  "/img/design3.png",
  "/img/design7.png",
  "/img/design4.png",
  "/img/design6.png",
];

const CustomQr = () => {
  const [imageIndex, setImageIndex] = useState(0);

  const { t } = useTranslation();

  const setting = {
    infinite: true,
    lazyload: true,
    slidesToShow: 3,
    centerMode: true,
    centerPadding: 0,
    autoplay: true,
    speed: 1000,
    autoplaySpeed: 3000,
    arrows: false,
    beforeChange: (current, next) => setImageIndex(next),
  };

  return (
    <div className="custom-qr-tag-by-user-parent-container">
      <div className="custom-qr-tag-by-user">
        <Slider className="orera" {...setting}>
          {customQrDesign.map((img, idx) => (
            <div
              className={
                "custom-qr-tag-by-user-img-box " +
                (idx === imageIndex ? "slide activeSlide" : "slide")
              }
            >
              <img src={img} alt={img} />
            </div>
          ))}
        </Slider>
      </div>
      <div className="custom-qr-tag-by-user-text">
        <p>{t("order your own design")}</p>
        <h3>
          {t("best gift")}
          {t("for your pets")}
        </h3>
        <Link to="/order/qrcollar"> {t("buy now")} </Link>
      </div>
    </div>
  );
};

export default CustomQr;
